import '../styles/LoginPage.css'

import React from 'react';
import { useTranslation } from "react-i18next";

import LoginButton from './LoginButton';

function RegisterConfirm() {

    const { t } = useTranslation();
    return (
        <div className='login-form'>
            <div className='register-form'>
                <div className='register-titles register-form-titles'>
                    <span className='login-form-titles'>{t('registerConfirm.title')}</span>
                    <span>{t('registerConfirm.subTitle')}</span>
                    <span>{t('registerConfirm.subTitle2')}</span>
                </div>
            </div>
            <LoginButton />
        </div>

    );
};
export default RegisterConfirm;