// revoir l'affichage du statut de la registration

import '../../styles/CandidatePage.css'

import React, { useState, useContext, useEffect } from 'react';

import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';

import { getMyPersonalInfo } from '../../services/personalInfo.service';
import { getMyExpInfo } from '../../services/expInfo.service';
import { getMyCompanyInfo } from '../../services/companyInfo.service';
import { getMyTestInfo } from '../../services/testInfo.service';

import PersonalInfoPage from './PersonalInfoPage';
import ExpInfoPage from './ExpInfoPage';
import CompanyInfoPage from './CompanyInfoPage';
import TestPage from './TestPage';

import { UserInfosContext } from '../../context/UserInfosContext';
import { Navigate, NavLink, Route, Routes } from 'react-router-dom';
import { RiCheckLine, RiHistoryFill } from 'react-icons/ri';
import { ResponseSnackbarErrorHandler } from '../../components/ResponseSnackbar';
import { getMyRegistration, submitRegistrationMe } from '../../services/registration.service';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import RegisterHistory from '../../components/RegisterHistory';

function CandidatePage() {

  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient()
  const { setSnackbarObj } = useContext(UserInfosContext);

  const [personalIsChecked, setPersonalIsChecked] = useState(false);
  const [expIsChecked, setExpIsChecked] = useState(false);
  const [companyIsChecked, setCompanyIsChecked] = useState(false);
  const [testIsChecked, setTestIsChecked] = useState(false);

  const [historyDialog, setHistoryDialog] = useState(false);
  const handleHistoryOpen = () => { setHistoryDialog(true); };
  const handleHistoryClose = () => { setHistoryDialog(false) };

  function switchButtonLabel(status) {
    switch (status) {
      case 'Pending': return t('candidatePage.button-validation-Pending');
      case 'InProgress': return t('candidatePage.button-validation-InProgress');
      case 'Validated': return t('candidatePage.button-validation-Validated');
      default: return t('candidatePage.button-validation-InProgress')
    }
  }

  const submitUserMeMutation = useMutation({
    enabled: !!getAccessTokenSilently, // ensures the query is only executed when these conditions are met.
    mutationFn: () => getAccessTokenSilently().then((token) => submitRegistrationMe(token)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['myRegistration'] }) //invalid query data, need refetch
      setSnackbarObj({
        message: t("snackbar.submitted"),
        status: 'success',
        timestamp: new Date().getTime()
      })
    },
    onError: (error) => {
      const snackbarError = ResponseSnackbarErrorHandler('myRegistration', error)
      if (snackbarError) { setSnackbarObj(snackbarError) }
    }
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    submitUserMeMutation.mutate()
  }

  const {
    data: myRegistration,
    isLoading: loadingMyRegistration,
    error: errorMyRegistration,
    failureReason: failureMyRegistration,
    failureCount: failureCountMyRegistration,
    isFetching: fetchingMyRegistration
  } = useQuery({
    enabled: !!getAccessTokenSilently, // ensures the query is only executed when getAccessTokenSilently is available.
    queryKey: ['myRegistration'],
    queryFn: () => getAccessTokenSilently().then((token) => getMyRegistration(token)),
  });

  const {
    data: myPersonalInfos,
    isLoading: loadingMyPersonalInfos,
    error: errorMyPersonalInfos,
    failureReason: failureMyPersonalInfos,
    failureCount: failureCountMyPersonalInfos,
    isFetching: fetchingMyPersonalInfos
  } = useQuery({
    enabled: !!getAccessTokenSilently, // ensures the query is only executed when getAccessTokenSilently is available.
    queryKey: ['myPersonalInfo'],
    queryFn: () => getAccessTokenSilently().then((token) => getMyPersonalInfo(token)),
  });

  const {
    data: myCompanyInfos,
    isLoading: loadingMyCompanyInfos,
    error: errorMyCompanyInfos,
    failureReason: failureMyCompanyInfos,
    failureCount: failureCountMyCompanyInfos,
    isFetching: fetchingMyCompanyInfos
  } = useQuery({
    enabled: !!getAccessTokenSilently, // ensures the query is only executed when getAccessTokenSilently is available.
    queryKey: ['myCompanyInfo'],
    queryFn: () => getAccessTokenSilently().then((token) => getMyCompanyInfo(token)),
  });

  const {
    data: myExpInfos,
    isLoading: loadingMyExpInfos,
    error: errorMyExpInfos,
    failureReason: failureMyExpInfos,
    failureCount: failureCountMyExpInfos,
    isFetching: fetchingMyExpInfos
  } = useQuery({
    enabled: !!getAccessTokenSilently, // ensures the query is only executed when getAccessTokenSilently is available.
    queryKey: ['myExpInfo'],
    queryFn: () => getAccessTokenSilently().then((token) => getMyExpInfo(token)),
  });

  const {
    data: myTestInfos,
    isLoading: loadingMyTestInfos,
    error: errorMyTestInfos,
    failureReason: failureMyTestInfos,
    failureCount: failureCountMyTestInfos,
    isFetching: fetchingMyTestInfos
  } = useQuery({
    enabled: !!getAccessTokenSilently, // ensures the query is only executed when getAccessTokenSilently is available.
    queryKey: ['myTestInfo'],
    queryFn: () => getAccessTokenSilently().then((token) => getMyTestInfo(token)),
  });


  useEffect(() => {
    if ((myPersonalInfos?.civility && myPersonalInfos?.lastName && myPersonalInfos?.firstName && myPersonalInfos?.birthDate && myPersonalInfos?.nationality && myPersonalInfos?.phoneNumber && myPersonalInfos?.address && myPersonalInfos?.city && myPersonalInfos?.postalCode && myPersonalInfos?.country && myPersonalInfos?.identity_proof)) {
      setPersonalIsChecked(true)
    }
  }, [myPersonalInfos])

  useEffect(() => {
    if ((myExpInfos?.lastPosition && myExpInfos.yearsExp && myExpInfos.diploma && (myExpInfos.linkedin || myExpInfos.resume))) {
      setExpIsChecked(true)
    }
  }, [myExpInfos])

  useEffect(() => {
    if ((myCompanyInfos?.identification && myCompanyInfos.number && myCompanyInfos.finance_iban && myCompanyInfos.finance_bic && myCompanyInfos.address && myCompanyInfos.city && myCompanyInfos.postalCode && myCompanyInfos.country)) {
      setCompanyIsChecked(true)
    }
  }, [myCompanyInfos])

  useEffect(() => {
    if ((myTestInfos?.analystTest?.status === "Obtained" || myTestInfos?.hunterTest?.status === "Obtained")) {
      setTestIsChecked(true)
    }
  }, [myTestInfos])

  useEffect(() => {

    const snackbarRegistration = ResponseSnackbarErrorHandler('myRegistration', errorMyRegistration, failureMyRegistration, failureCountMyRegistration)
    if (snackbarRegistration) { setSnackbarObj(snackbarRegistration) }

    const snackbarPersonalInfos = ResponseSnackbarErrorHandler('myPersonalInfo', errorMyPersonalInfos, failureMyPersonalInfos, failureCountMyPersonalInfos)
    if (snackbarPersonalInfos) { setSnackbarObj(snackbarPersonalInfos) }

    const snackbarExpInfos = ResponseSnackbarErrorHandler('myExpInfo', errorMyExpInfos, failureMyExpInfos, failureCountMyExpInfos)
    if (snackbarExpInfos) { setSnackbarObj(snackbarExpInfos) }

    const snackbarCompanyInfos = ResponseSnackbarErrorHandler('myCompanyInfo', errorMyCompanyInfos, failureMyCompanyInfos, failureCountMyCompanyInfos)
    if (snackbarCompanyInfos) { setSnackbarObj(snackbarCompanyInfos) }

    const snackbarTestInfos = ResponseSnackbarErrorHandler('myTestInfo', errorMyTestInfos, failureMyTestInfos, failureCountMyTestInfos)
    if (snackbarTestInfos) { setSnackbarObj(snackbarTestInfos) }

  }, [errorMyRegistration, errorMyCompanyInfos, errorMyExpInfos, errorMyPersonalInfos, errorMyTestInfos, failureMyRegistration, failureCountMyRegistration, failureCountMyCompanyInfos, failureCountMyExpInfos, failureCountMyPersonalInfos, failureCountMyTestInfos, failureMyCompanyInfos, failureMyExpInfos, failureMyPersonalInfos, failureMyTestInfos, setSnackbarObj]);


  //if (loadingMyRegistration || fetchingMyRegistration) return <Loader />

  return (<div className='registration__container'>

    <h2 className='registration__header'>{t('welcomeView.title')} <RiHistoryFill title={t('header.label-History')} aria-label={t('header.label-History')} data-rel="tooltip" data-html="true" data-original-title="History" data-placement="bottom" onClick={() => handleHistoryOpen()} style={{ cursor: 'pointer' }} /></h2>
    <p>{t('welcomeView.line1')}<br />{t('welcomeView.line2')}</p>
    <div className='candidate-container'>
      {/* <div className="menu-title"> */}
      <div className="registration__menu-tab-bar">
        <ul className='registration-menu'>
          <li><NavLink activeclassname={"active"} to='./personal'>{t('registerMenu.personalInfo')}</NavLink>{personalIsChecked ? <RiCheckLine /> : null}</li>
          <li><NavLink activeclassname={"active"} to={'./experience'}>{t('registerMenu.expInfo')}</NavLink>{expIsChecked ? <RiCheckLine /> : null}</li>
          <li><NavLink activeclassname={"active"} to={'./company'}>{t('registerMenu.companyInfo')} </NavLink>{companyIsChecked ? <RiCheckLine /> : null}</li>
          <li><NavLink activeclassname={"active"} to={'./test'}>{t('registerMenu.test')}</NavLink>{testIsChecked ? <RiCheckLine /> : null}</li>
        </ul>
        {personalIsChecked && expIsChecked && companyIsChecked && testIsChecked ?
          <Button
            variant="contained"
            disabled={!(myRegistration?.status === 'InProgress')}
            sx={{
              backgroundColor: "var(--button-background-6)",
              textTransform: 'none',
              fontSize: '14px', fontWeight: '400', padding: '6px 10px', margin: '20px auto',
              ':hover': { backgroundColor: "var(--button-background-hover-6)" }
            }}
            onClick={(e) => { handleSubmit(e) }}
          >
            {switchButtonLabel(myRegistration?.status)}
          </Button> : null}
      </div>
      {/* <div className='routes-container'> */}
      <Routes>
        <Route path="personal" element={<PersonalInfoPage personalInfos={myPersonalInfos} loadingData={(loadingMyPersonalInfos && fetchingMyPersonalInfos)} />} />
        <Route path="experience" element={<ExpInfoPage expInfos={myExpInfos} loadingData={(loadingMyExpInfos && fetchingMyExpInfos)} />} />
        <Route path="company" element={<CompanyInfoPage companyInfos={myCompanyInfos} personalInfos={myPersonalInfos} loadingData={(loadingMyCompanyInfos && fetchingMyCompanyInfos) || (loadingMyPersonalInfos && fetchingMyPersonalInfos)} />} />
        <Route path="test" element={<TestPage testInfos={myTestInfos} loadingData={(loadingMyTestInfos && fetchingMyTestInfos)} />} />

        {<Route path="*" element={<Navigate to="personal" replace />} />}
      </Routes>
    </div>
    {/* History */}
    <Dialog
      fullWidth
      maxWidth='md'
      open={historyDialog}
      onClose={() => handleHistoryClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t('header.label-History')}
      </DialogTitle>
      <DialogContent sx={{padding: {xs:0, sm:'16px'}}}>
        <RegisterHistory history={myRegistration?.history} loadingData={(fetchingMyRegistration && loadingMyRegistration)} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleHistoryClose()}>{t('button.close')}</Button>
      </DialogActions>
    </Dialog>
  </div>
  );
};
export default CandidatePage;