
import React, { useContext } from 'react'

import { useTranslation } from "react-i18next";
import { useAuth0 } from '@auth0/auth0-react';

import { CircularProgress, Button, Box } from '@mui/material';

import { RiFileDownloadLine, RiCheckLine } from 'react-icons/ri';

import { UserInfosContext } from '../context/UserInfosContext';


export default function DownloadButton(props) {

    const { t } = useTranslation();
    const { getAccessTokenSilently } = useAuth0();
    const { setSnackbarObj } = useContext(UserInfosContext);

    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);


    const handleButtonClick = () => {
        if (!loading) {
            setSuccess(false);
            setLoading(true);

            // download
            getAccessTokenSilently()
                .then((token) => {
                    props.downloadFunction({ ...props.downloadParameters, token: token })
                        // .then((response) => response.blob())
                        .then((blob) => {
                            // download finished
                            // 2. Create blob link to download
                            const url = window.URL.createObjectURL(new Blob([blob]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', props.fileName);
                            link.hidden = true;
                            // 3. Append to html page
                            document.body.appendChild(link);
                            // 4. Force download
                            link.click();
                            // 5. Clean up and remove the link
                            link.parentNode.removeChild(link);
                            setSuccess(true);
                            setLoading(false)
                        })
                })
                .catch((error) => {
                    console.log(error);

                    setSnackbarObj({
                        message: error.statusText,
                        responseCode: error.status,
                        status: 'error',
                        request : 'download',
                        timestamp: new Date().getTime()
                    })

                    setLoading(false)
                });
        }
    };


    return (
        <Box sx={{ m: 0, position: 'relative', display: 'inline' }}>
            <Button
                variant="contained"
                startIcon={success ? <RiCheckLine /> : <RiFileDownloadLine />}
                sx={{
                    backgroundColor: "var(--button-background-6)",
                    textTransform: 'none',
                    fontSize: '14px', fontWeight: '400', padding: '6px 12px', margin: '0px',
                    ':hover': { backgroundColor: "var(--button-background-hover-6)" },
                }}
                disabled={loading}
                onClick={handleButtonClick}
            >
                {loading ? t('clientSettings.downloadButton.preparing-the-file') : (success ? t('clientSettings.downloadButton.success') : props.label)}
            </Button>
            {loading && (
                <CircularProgress
                    size={24}
                    sx={{
                        color: "var(--button-background-6)",
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                    }}
                />
            )}
        </Box>
    )
}