import React, { useState, useContext } from "react";

import '../../styles/InfoPages.css'

import { useTranslation } from "react-i18next";
import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Button, TextField, Select, MenuItem, Grid, Skeleton } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogActions, FormControlLabel, Checkbox } from '@mui/material';

import { RiSave2Line, RiCheckLine, RiExternalLinkLine } from 'react-icons/ri';

import { ResponseSnackbarErrorHandler } from '../../components/ResponseSnackbar';
import { putMyCompanyInfo, postBillingMandate } from '../../services/companyInfo.service';
import { UserInfosContext } from '../../context/UserInfosContext';

import { countriesList } from '../../data/countries';

function CompanyInfoPage({ companyInfos, personalInfos, loadingData }) {

    const { t } = useTranslation();
    const { getAccessTokenSilently } = useAuth0();
    const queryClient = useQueryClient()
    const { setSnackbarObj } = useContext(UserInfosContext);

    const [profileFormValues, setProfileFormValues] = useState(companyInfos || {})
    const [checked, setChecked] = useState(false);
    const [checkedTerms, setCheckedTerms] = useState(companyInfos?.terms);
    const [checkedCertified, setCheckedCertified] = useState(companyInfos?.certified);
    const [checkedVAT, setCheckedVAT] = useState(companyInfos?.VAT === true);
    const [checkedNotVAT, setCheckedNotVAT] = useState(companyInfos?.VAT === false);
    const [billingMandateIsChecked, setBillingMandateIsChecked] = useState(companyInfos?.terms && companyInfos?.certified);
    const [dialOpen, setDialOpen] = useState(false);

    // validation failed indicators
    const [profileFormErrors, setProfileFormErrors] = useState({
        identification: false,
        number: false,
        address: false,
        additionalAddress: false,
        city: false,
        postalCode: false,
        country: false,
        finance_iban: false,
        finance_bic: false,
        billingMandate: false
    });

    // has user done at least one thing on the field (to avoid displaying an error at 1st form display)
    const [profileFormTouches, setProfileFormTouches] = useState({
        identification: false,
        number: false,
        address: false,
        additionalAddress: false,
        city: false,
        postalCode: false,
        country: false,
        finance_iban: false,
        finance_bic: false,
        billingMandate: false
    });

    // update the react state with user input
    const handleInputChange = e => {
        const { name, value } = e.target
        setProfileFormValues({ ...profileFormValues, [name]: value });
    }

    // // les companyInfos arrivent en props -> pas prêtes -> useEffect
    // useEffect(() => {
    //     if (companyInfos) {
    //         setProfileFormValues(companyInfos);
    //         setBillingMandateIsChecked(companyInfos.terms && companyInfos.certified)
    //         setCheckedVAT(companyInfos.VAT === true)
    //         setCheckedNotVAT(companyInfos.VAT === false)
    //         setCheckedTerms(companyInfos.terms)
    //         setCheckedCertified(companyInfos.certified)
    //     }

    // }, [companyInfos])

    // update the validation infos when user leaves the field (used only on fields requiring validation)
    const handleBlur = e => {
        const { name,
            value
        } = e.target

        profileFormValues[name] ? setProfileFormErrors({ ...profileFormErrors, [name]: false }) : setProfileFormErrors({ ...profileFormErrors, [name]: true });
        setProfileFormTouches({ ...profileFormTouches, [name]: true });

        // IBAN : MinLength 20, max 27
        if (name === 'finance_iban' && (value.length < 20 || value.length > 27)) setProfileFormErrors({ ...profileFormErrors, [name]: true });

        // BIC : MinLength 8, max 11
        if (name === 'finance_bic' && (value.length < 8 || value.length > 11)) setProfileFormErrors({ ...profileFormErrors, [name]: true });
    }

    // submit button: enabled only when everything is alright
    function isEnabled() {
        console.log(profileFormErrors, profileFormValues, billingMandateIsChecked)
        return (
            (profileFormValues.identification && profileFormValues.number && profileFormValues.address && profileFormValues.city && profileFormValues.postalCode && profileFormValues.country && profileFormValues.finance_iban && profileFormValues.finance_bic)
            &&
            (!profileFormErrors.identification && !profileFormErrors.number && !profileFormErrors.address && !profileFormErrors.city && !profileFormErrors.postalCode && !profileFormErrors.country && !profileFormErrors.finance_iban && !profileFormErrors.finance_bic)
            &&
            (billingMandateIsChecked)
        )
    }

    const handleCheck = () => {
        setChecked(!checked)
        if (!checked) {
            setProfileFormValues({
                ...profileFormValues,
                address: personalInfos.address,
                additionalAddress: personalInfos.additionalAddress,
                city: personalInfos.city,
                postalCode: personalInfos.postalCode,
                country: personalInfos.country,
            });
            setProfileFormErrors({
                ...profileFormErrors,
                address: false,
                additionalAddress: false,
                city: false,
                postalCode: false,
                country: false,
            });
        } else {
            setProfileFormValues({
                ...profileFormValues,
                address: '',
                additionalAddress: '',
                city: '',
                postalCode: '',
                country: '',
            });
        }
    };

    const handleCheckTerms = () => { setCheckedTerms(!checkedTerms) };
    const handleCheckCertified = () => { setCheckedCertified(!checkedCertified) };
    const handleCheckVAT = () => {
        setCheckedVAT(!checkedVAT)
        if (!checkedVAT) { setCheckedNotVAT(false) }
    };
    const handleCheckNotVAT = () => {
        setCheckedNotVAT(!checkedNotVAT)
        if (!checkedNotVAT) { setCheckedVAT(false) }
    };

    const putMyCompanyInfoMutation = useMutation({
        enabled: !!getAccessTokenSilently, // ensures the query is only executed when these conditions are met.
        mutationFn: () => getAccessTokenSilently().then((token) => putMyCompanyInfo(token, profileFormValues)),
        onSuccess: (data) => {
            queryClient.setQueryData(['myCompanyInfo'], data) //update query data 
            setSnackbarObj({
                message: t("snackbar.saved"),
                status: 'success',
                timestamp: new Date().getTime()
            })
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
        },
        onError: (error) => {
            const snackbarError = ResponseSnackbarErrorHandler('myCompanyInfo', error)
            if (snackbarError) { setSnackbarObj(snackbarError) }
        }
    })

    const handleSubmit = (e) => {
        e.preventDefault()
        putMyCompanyInfoMutation.mutate()
    }

    const handleClickOpen = () => { setDialOpen(true); };
    const handleClose = () => {
        setDialOpen(false)
        setProfileFormTouches({ ...profileFormTouches, billingMandate: true })
    };

    const postBillingMandateMutation = useMutation({
        enabled: !!getAccessTokenSilently, // ensures the query is only executed when these conditions are met.
        mutationFn: () => getAccessTokenSilently().then((token) => postBillingMandate(token, { VAT: checkedVAT })),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['myCompanyInfo'] }) //invalid query data, need refetch
            setSnackbarObj({
                message: t("snackbar.saved"),
                status: 'success',
                timestamp: new Date().getTime()
            })
            setBillingMandateIsChecked(true)
            handleClose()
        },
        onError: (error) => {
            const snackbarError = ResponseSnackbarErrorHandler('myCompanyInfo', error)
            if (snackbarError) { setSnackbarObj(snackbarError) }
        }
    })

    const handleAccept = (e) => {
        e.preventDefault()
        postBillingMandateMutation.mutate({})
    }


    return (
        <div className='information-form'>
            {/* {loader ? <Loader /> : null} */}
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} marginBottom={'10px'}>
                    <h3>{t('welcomeView.companyInfo-title')}</h3>
                    <p>{t('welcomeView.companyInfo-text')}</p>
                </Grid>
                <Grid item md={5}>
                    <div className="information-form-title">
                        <span>
                            {t('companyInfoPage.identification')}
                            <sup>*</sup>
                        </span>
                    </div>
                </Grid>
                <Grid item xs={12} md={7}>
                    {loadingData
                        ? <Skeleton variant="rounded" height={'40px'} />
                        : <TextField
                            fullWidth
                            size="small"
                            required
                            name="identification"
                            // label={t('companyInfoPage.identification')}
                            variant="outlined"
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            value={profileFormValues.identification}
                            error={profileFormTouches.identification && profileFormErrors.identification}
                        // helperText={profileFormTouches.identification && profileFormErrors.identification && t('companyInfoPage.field-down-helper')}
                        // InputLabelProps={profileFormValues.identification ? { shrink: true } : null}
                        />
                    }
                </Grid>
                <Grid item md={5}>
                    <div className="information-form-title">
                        <span>
                            {t('companyInfoPage.number')}
                            <sup>*</sup>
                        </span>
                    </div>
                </Grid>
                <Grid item xs={12} md={7}>
                    {loadingData
                        ? <Skeleton variant="rounded" height={'40px'} />
                        : <TextField
                            fullWidth size="small"
                            required
                            name="number"
                            // label={t('companyInfoPage.number')}
                            variant="outlined"
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            value={profileFormValues.number}
                            error={profileFormTouches.number && profileFormErrors.number}
                        // helperText={profileFormTouches.number && profileFormErrors.number && t('companyInfoPage.field-down-helper')}
                        // InputLabelProps={profileFormValues.number ? { shrink: true } : null}
                        />
                    }
                </Grid>
                <Grid item md={12} textAlign={'right'}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checked}
                                onChange={handleCheck}
                            />
                        }
                        label={t('companyInfoPage.sameAddress')}
                        sx={{ marginLeft: '-8px', marginRight: '0px' }}
                    />
                    {/* <span>    {t('companyInfoPage.sameAddress')} </span> */}
                </Grid>
                <Grid item md={5}>
                    <div className="information-form-title">
                        <span>
                            {t('companyInfoPage.address')}
                            <sup>*</sup>
                        </span>
                    </div>
                </Grid>
                <Grid item xs={12} md={7} >
                    {loadingData
                        ? <Skeleton variant="rounded" height={'40px'} />
                        : <TextField
                            disabled={checked}
                            fullWidth
                            size="small"
                            required
                            name="address"
                            // label={t('companyInfoPage.address')}
                            variant="outlined"
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            value={profileFormValues.address}
                            error={profileFormTouches.address && profileFormErrors.address}
                        // helperText={profileFormTouches.address && profileFormErrors.address && t('companyInfoPage.field-down-helper')}
                        // InputLabelProps={profileFormValues.address ? { shrink: true } : null}
                        />
                    }
                </Grid>
                <Grid item md={5}>
                    <div className="information-form-title">
                        <span>
                            {t('companyInfoPage.additionalAddress')}
                        </span>
                        <span className="information-form-title-optional">
                            {t('companyInfoPage.optional')}
                        </span>
                    </div>
                </Grid>
                <Grid item xs={12} md={7}>
                    {loadingData
                        ? <Skeleton variant="rounded" height={'40px'} />
                        : <TextField
                            disabled={checked}
                            fullWidth
                            size="small"
                            name="additionalAddress"
                            // label={t('companyInfoPage.additionalAddress')}
                            variant="outlined"
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            value={profileFormValues.additionalAddress}
                            // error={profileFormTouches.additionalAddress && profileFormErrors.additionalAddress}
                            // helperText={profileFormTouches.additionalAddress && profileFormErrors.additionalAddress && t('companyInfoPage.field-down-helper')}
                            InputLabelProps={profileFormValues.additionalAddress ? { shrink: true } : null}
                        />
                    }
                </Grid>
                <Grid item md={5}>
                    <div className="information-form-title">
                        <span>
                            {t('companyInfoPage.city')}
                            <sup>*</sup>
                        </span>
                    </div>
                </Grid>
                <Grid item xs={12} md={7}>
                    {loadingData
                        ? <Skeleton variant="rounded" height={'40px'} />
                        : <TextField
                            disabled={checked}
                            fullWidth
                            size="small"
                            required
                            name="city"
                            // label={t('companyInfoPage.city')}
                            variant="outlined"
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            value={profileFormValues.city}
                            error={profileFormTouches.city && profileFormErrors.city}
                        // helperText={profileFormTouches.city && profileFormErrors.city && t('companyInfoPage.field-down-helper')}
                        // InputLabelProps={profileFormValues.city ? { shrink: true } : null}
                        />
                    }
                </Grid>
                <Grid item md={5}>
                    <div className="information-form-title">
                        <span>
                            {t('companyInfoPage.postalCode')}
                            <sup>*</sup>
                        </span>
                    </div>
                </Grid>
                <Grid item xs={12} md={7}>
                    {loadingData
                        ? <Skeleton variant="rounded" height={'40px'} />
                        : <TextField
                            disabled={checked}
                            fullWidth
                            size="small"
                            required
                            name="postalCode"
                            // label={t('companyInfoPage.postalCode')}
                            variant="outlined"
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            value={profileFormValues.postalCode}
                            error={profileFormTouches.postalCode && profileFormErrors.postalCode}
                        // helperText={profileFormTouches.postalCode && profileFormErrors.postalCode && t('companyInfoPage.field-down-helper')}
                        // InputLabelProps={profileFormValues.postalCode ? { shrink: true } : null}
                        />
                    }
                </Grid>
                <Grid item md={5}>
                    <div className="information-form-title">
                        <span>
                            {t('companyInfoPage.country')}
                            <sup>*</sup>
                        </span>
                    </div>
                </Grid>
                <Grid item xs={12} md={7}>
                    {loadingData
                        ? <Skeleton variant="rounded" height={'40px'} />
                        : checked
                            ? <TextField
                                disabled
                                fullWidth
                                size="small"
                                name="country"
                                // label={t('personalInfoPage.country')}
                                variant="outlined"
                                value={profileFormValues.country}
                            />
                            : <Select
                                disabled={checked}
                                fullWidth
                                size="small"
                                name="country"
                                // label={t('personalInfoPage.country')}
                                variant="outlined"
                                onChange={handleInputChange}
                                onBlur={handleBlur}
                                value={profileFormValues.country || ''}
                                error={profileFormTouches.country && profileFormErrors.country}
                                // helperText={profileFormTouches.country && profileFormErrors.country && t('personalInfoPage.field-down-helper')}
                                sx={{
                                    textAlign: 'start'
                                }}
                            >
                                {countriesList.map((option) => (
                                    <MenuItem key={option.en_short_name} value={option.en_short_name}>
                                        {option.en_short_name}
                                    </MenuItem>
                                ))}
                            </Select>
                    }
                </Grid>
            </Grid>
            <div className="separator"></div>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item md={5}>
                    <div className="information-form-title">
                        <span style={{ color: (profileFormTouches.billingMandate && !billingMandateIsChecked) ? "red" : null }}>
                            {t('companyInfoPage.billingMandate')}<sup>*</sup>
                        </span>
                    </div>
                </Grid>
                <Grid item xs={12} md={7}
                    container
                    direction="row">
                    <Button
                        variant="contained"
                        component="label"
                        title={t('button.upload')}
                        aria-label={t('button.upload')}
                        startIcon={billingMandateIsChecked ? <RiCheckLine /> : <RiExternalLinkLine />}
                        sx={{
                            backgroundColor: "var(--button-background-4)",
                            textTransform: 'none',
                            fontSize: '14px', fontWeight: '400', padding: '6px 10px',
                            ':hover': { backgroundColor: "var(--button-background-hover-4)" },
                        }}
                        onClick={() => handleClickOpen()}
                    >
                        {t('button.display')}
                    </Button>
                </Grid>
                <Grid item md={5}>
                    <div className="information-form-title">
                        <span>
                            {t('companyInfoPage.IBAN')}
                            <sup>*</sup>
                        </span>
                    </div>
                </Grid>
                <Grid item xs={12} md={7}>
                    {loadingData
                        ? <Skeleton variant="rounded" height={'40px'} />
                        : <TextField
                            fullWidth
                            size="small"
                            required
                            name="finance_iban"
                            // label={t('companyInfoPage.postalCode')}
                            variant="outlined"
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            value={profileFormValues.finance_iban}
                            error={profileFormTouches.finance_iban && profileFormErrors.finance_iban}
                            placeholder="ex: FR0201525085247025989315498 (20-27 chars)"
                        // helperText={profileFormTouches.postalCode && profileFormErrors.postalCode && t('companyInfoPage.field-down-helper')}
                        // InputLabelProps={profileFormValues.postalCode ? { shrink: true } : null}
                        />
                    }
                </Grid>
                <Grid item md={5}>
                    <div className="information-form-title">
                        <span>
                            {t('companyInfoPage.BIC')}
                            <sup>*</sup>
                        </span>
                    </div>
                </Grid>
                <Grid item xs={12} md={7}>
                    {loadingData
                        ? <Skeleton variant="rounded" height={'40px'} />
                        : <TextField
                            fullWidth
                            size="small"
                            required
                            name="finance_bic"
                            // label={t('companyInfoPage.postalCode')}
                            variant="outlined"
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            value={profileFormValues.finance_bic}
                            error={profileFormTouches.finance_bic && profileFormErrors.finance_bic}
                            placeholder="ex: BOUSFRPPXXX"
                        // helperText={profileFormTouches.postalCode && profileFormErrors.postalCode && t('companyInfoPage.field-down-helper')}
                        // InputLabelProps={profileFormValues.postalCode ? { shrink: true } : null}
                        />
                    }
                </Grid>
                <Grid item xs={12} textAlign={'right'} marginTop={"30px"}>
                    <Button
                        variant="contained"
                        disabled={!isEnabled()}
                        startIcon={<RiSave2Line />}
                        sx={{
                            backgroundColor: "var(--button-background-6)",
                            textTransform: 'none',
                            fontSize: '14px', fontWeight: '400', padding: '6px 10px',
                            ':hover': { backgroundColor: "var(--button-background-hover-6)" }
                        }}
                        onClick={(e) => { handleSubmit(e) }}
                    >
                        {t('button.save')}
                    </Button>
                </Grid>
            </Grid>
            <Dialog
                fullWidth
                maxWidth='md'
                open={dialOpen}
                onClose={() => handleClose()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t('companyInfoPage.billingMandate')}
                </DialogTitle>
                <DialogContent>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checkedVAT}
                                onChange={handleCheckVAT}
                            />
                        }
                        label={t('billingMandate.VAT')}
                        sx={{ marginLeft: '-8px', marginRight: '28px' }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checkedNotVAT}
                                onChange={handleCheckNotVAT}
                            />
                        }
                        label={t('billingMandate.notVAT')}
                        sx={{ marginLeft: '-8px', marginRight: '0px' }}
                    />
                    <p>{t('billingMandate.term')}<br />{t('billingMandate.term2')}</p>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checkedTerms}
                                onChange={handleCheckTerms}
                            />
                        }
                        label={t('billingMandate.acceptTerms')}
                        sx={{ marginLeft: '-8px', marginRight: '0px' }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checkedCertified}
                                onChange={handleCheckCertified}
                            />
                        }
                        label={t('billingMandate.certified')}
                        sx={{ marginLeft: '-8px', marginRight: '0px' }}
                    />
                    <p>{t('billingMandate.endLine')}</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose()}>{t('button.cancel')}</Button>
                    <Button
                        disabled={!(checkedTerms && checkedCertified && (checkedVAT || checkedNotVAT))}
                        onClick={(e) => handleAccept(e)}>{t('button.accept')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
export default CompanyInfoPage;
