import { useContext } from 'react'
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { RiPieChartLine, RiAlertLine, RiSearchLine, RiFocus3Line, RiSettings3Line, RiBuildingLine } from "react-icons/ri";
import { FaWikipediaW } from "react-icons/fa";


import { UserInfosContext } from '../context/UserInfosContext';

function NavBarLinks({ navBarLinks, navBarLinkActive }) {
  const { t } = useTranslation();
  const location = useLocation()

  const { userInfos } = useContext(UserInfosContext);

  return (
    <>
      {userInfos.profile === 'Client' || userInfos.profile === 'Admin' || userInfos.profile === 'Analyst_L2' || userInfos.profile === 'Hunter-Analyst' 
        ? <li className={navBarLinks}><NavLink to='/statistics'
          className={location.pathname.startsWith('/statistics') ? navBarLinkActive : ''}>
          <RiPieChartLine /><span>{t('mainNav.Statistics')}</span>
        </NavLink></li>
        : null}
      {userInfos.profile.includes('Analyst') || userInfos.profile === 'Admin'
        ? <li className={navBarLinks}><NavLink to='/alerts'
          className={location.pathname.startsWith('/alerts') ? navBarLinkActive : ''}>
          <RiAlertLine /><span>{t('mainNav.Alerts')}</span>
        </NavLink></li>
        : null}
      {userInfos.profile.includes('Analyst') || userInfos.profile === 'Admin'
        ? <li className={navBarLinks}><NavLink to='/wikis'
          className={location.pathname.startsWith('/wikis') ? navBarLinkActive : ''}>
          <FaWikipediaW /><span>{t('mainNav.Contributions')}</span>
        </NavLink></li>
        : null}
      {userInfos.profile === 'Client' || userInfos.profile === 'Analyst_L2' || userInfos.profile === 'Admin'
        ? <li className={navBarLinks}><NavLink to='/investigations'
          className={location.pathname.startsWith('/investigations') ? navBarLinkActive : ''}>
          <RiSearchLine /><span>{t('mainNav.Investigations')}</span>
        </NavLink></li>
        : null}
      {userInfos.profile === 'Hunter' || userInfos.profile === 'Hunter-Analyst' || userInfos.profile === 'Admin' || userInfos.profile === 'Client'
        ? <li className={navBarLinks}><NavLink to='/campaigns'
          className={location.pathname.startsWith('/campaigns') ? navBarLinkActive : ''}>
          <RiFocus3Line /><span>{t('mainNav.Hunts')}</span>
        </NavLink></li>
        : null}
      {userInfos.profile === 'Admin'
        ? <li className={navBarLinks}><NavLink to='/admin'
          className={location.pathname.startsWith('/admin') ? navBarLinkActive : ''}>
          <RiSettings3Line /><span>{t('mainNav.Admin')}</span>
        </NavLink></li>
        : null}
      {userInfos.profile === 'Client'
        ? <li className={navBarLinks}><NavLink to='/settings'
          className={location.pathname.startsWith('/settings') ? navBarLinkActive : ''}>
          <RiSettings3Line /><span>{t('mainNav.Settings')}</span>
        </NavLink></li>
        : null}
      {userInfos.profile === 'Candidate' || userInfos.profile === 'Hunter' || userInfos.profile === 'Hunter-Analyst' || userInfos.profile === 'Analyst_L1'
        ? <li className={navBarLinks}><NavLink to='/registration'
          className={location.pathname.startsWith('/registration') ? navBarLinkActive : ''}>
          <RiBuildingLine /><span>{t('mainNav.MyCompany')}</span>
        </NavLink></li>
        : null}
    </>
  )
}

export default NavBarLinks