import '../../styles/Body.css'
import '../../App.css'

import { useState, useEffect, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom';

import { useTranslation } from "react-i18next";
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';

import { Grid, Skeleton } from '@mui/material';

import { RiBarChart2Line } from 'react-icons/ri';

import moment from 'moment';

import { ResponseSnackbarErrorHandler } from '../../components/ResponseSnackbar';
import { UserInfosContext } from '../../context/UserInfosContext';
import { fetchUserScoring } from '../../services/user.service';


function AnalystDashboard() {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate()

  const { userInfos, setSnackbarObj } = useContext(UserInfosContext);

  const [fromDateFormValue] = useState(moment().subtract(1, 'day'));
  const [toDateFormValue] = useState(moment());

  const params = useParams();
  const userId = userInfos.profile === "Admin" ? params : userInfos._id;

  const {
    data: userScoring,
    isLoading: loadingUserScoring,
    error: errorUserScoring,
    failureReason: failureUserScoring,
    failureCount: failureCountUserScoring,
    isFetching: fetchingUserScoring
  } = useQuery({
    enabled: !!getAccessTokenSilently && !!fromDateFormValue && !!toDateFormValue, // ensures the query is only executed when these conditions are met.
    queryKey: ['userScoring', { _id: userId, period: 'day', toDate: toDateFormValue }],
    queryFn: () => getAccessTokenSilently().then((token) => fetchUserScoring(token, {
      userId: userInfos._id,
      fromDate: fromDateFormValue.toISOString(),
      toDate: toDateFormValue.toISOString()
    })),
    staleTime: 60 * 60 * 1000, // 60 mins (duration data is considered fresh)
    cacheTime: 65 * 60 * 1000, // 65 mins (duration React Query stores inactive data before it is deleted from the cache)
  });


  useEffect(() => {
    const snackbarUserInfos = ResponseSnackbarErrorHandler('userScoring', errorUserScoring, failureUserScoring, failureCountUserScoring)
    if (snackbarUserInfos) { setSnackbarObj(snackbarUserInfos) }
  }, [errorUserScoring, failureCountUserScoring, failureUserScoring, setSnackbarObj]);

  /* Loader (Skeleton) when query is in InitialLoading and isFetching (to confirm that is enabled) */
  const loadingUserScoringData = (loadingUserScoring && fetchingUserScoring)

  return (
    <div className='nhub-overview-container'>
      <Grid container spacing={2}>
        <Grid item xs>
          <div className='widget chart cartOpening'
            onClick={() => {
                navigate('statistics/scoring')
            }}>
            <h5><RiBarChart2Line /> {t('statistics.scoring.page-title')}</h5>
            <div className="scoringWidgetCart">
              <div className="scoringWidget">
                {loadingUserScoringData ? <Skeleton variant='circular' width={'100%'} height={'100%'} animation="wave" /> :
                  userScoring[0] ?
                  <>
                    <h5>{t(`statistics.scoring.NOTE-title`)}</h5>
                    <span className='rankNote'> {userScoring[0]?.rank?.NOTE} </span>
                    <span className='rankNbTotal'> / {userScoring[0]?.rank?.NbTotalAnalysts}</span>
                  </>
                  : 'No data'
                }
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
export default AnalystDashboard