import React, { useState, useEffect } from 'react';

import '../styles/LoginPage.css'
import '../styles/RegisterForm.css'

import { useTranslation } from "react-i18next";
import { useMutation } from '@tanstack/react-query';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { TextField, CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import { Grid, InputAdornment } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { RiEyeLine, RiEyeOffLine, RiRestartLine, RiInformationLine, RiCheckboxCircleLine, RiFileCopyLine } from 'react-icons/ri';

import { ResponseSnackbarErrorHandler } from './ResponseSnackbar';
import RegisterConfirm from './RegisterConfirm'
import { ResponseSnackbar } from './ResponseSnackbar';
import { register, checkUsernames } from '../services/authentication.service';
import { randomPassword, strengthPassword } from '../utils/generatePassword';
import { isValidEmail, isValidDisplayName } from '../utils/regex';


const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});


function RegisterForm() {

    const { t } = useTranslation();

    const [showPassword, setShowPassword] = useState(false);
    const [confirmed, setConfirmed] = useState(false);
    const [loader, setLoader] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState({});
    const [openPasswordTooltip, setOpenPasswordTooltip] = useState(false);
    const [valueExist, setValueExist] = useState(0);
    const [snackbarObj, setSnackbarObj] = useState({});

    // react state. used for field validation and initial display
    const [profileFormValues, setProfileFormValues] = useState({
        displayName: '',
        email: '',
        password: '',
        GDPR: false,
        CGU: false,
        profile: 'Hunter-Analyst',
    });

    // validation failed indicators
    const [profileFormErrors, setProfileFormErrors] = useState({
        displayName: false,
        email: false,
        password: false,
        GDPR: false,
        CGU: false,
    });

    // has user done at least one thing on the field (to avoid displaying an error at 1st form display)
    const [profileFormTouches, setProfileFormTouches] = useState({
        displayName: false,
        email: false,
        password: false,
        GDPR: false,
        CGU: false,
    });

    // update the react state with user input
    const handleInputChange = e => {
        const { name, value } = e.target
        setProfileFormValues({ ...profileFormValues, [name]: value });
    }

    const handleChangeGDPR = () => {
        setProfileFormValues(profileFormValues => ({ ...profileFormValues, GDPR: !profileFormValues.GDPR }));
        setProfileFormTouches(profileFormTouches => ({ ...profileFormTouches, GDPR: true }));
    };
    const handleChangeCGU = () => {
        setProfileFormValues(profileFormValues => ({ ...profileFormValues, CGU: !profileFormValues.CGU }));
        setProfileFormTouches(profileFormTouches => ({ ...profileFormTouches, CGU: true }));
    };

    // update the validation infos when user leaves the field (used only on fields requiring validation)
    const handleBlur = e => {
        const { name, value } = e.target
        profileFormValues[name] ? setProfileFormErrors({ ...profileFormErrors, [name]: false }) : setProfileFormErrors({ ...profileFormErrors, [name]: true });
        setProfileFormTouches({ ...profileFormTouches, [name]: true });
        if (name === 'displayName' || (name === 'email' && isValidEmail(value))) { handleCheckUsernames() }
    }

    const handleTooltipClose = () => { setOpenPasswordTooltip(false); };
    const handleTooltipOpen = () => { setOpenPasswordTooltip(true); };
    const handleClickShowPassword = () => { setShowPassword(!showPassword); };

    // generate random password
    const generatePassword = () => {
        const pwd = randomPassword()
        setProfileFormValues(profileFormValues => ({ ...profileFormValues, password: pwd }));
    }

    // update value of "passwordStrength" on password Input changes
    useEffect(() => {
        let mounted = true;
        if (mounted) {
            setPasswordStrength(strengthPassword(profileFormValues.password))
        }
        return () => mounted = false;
    }, [profileFormValues.password])


    // submit button: enabled only when everything is alright
    function isEnabled() {
        return (
            (profileFormValues.displayName && profileFormValues.email && profileFormValues.password && profileFormValues.GDPR && profileFormValues.CGU)
            &&
            (!profileFormErrors.displayName && !profileFormErrors.email && !profileFormErrors.password && !profileFormErrors.GDPR && !profileFormErrors.CGU)
            &&
            (passwordStrength.length && passwordStrength.hasUpperCase && passwordStrength.hasLowerCase && passwordStrength.hasDigit && passwordStrength.hasSpecialChar)
            &&
            valueExist === 0 && isValidEmail(profileFormValues.email) && isValidDisplayName(profileFormValues.displayName) && profileFormValues.displayName.length < 50 &&
            !loader
        )
    }

    const registerMutation = useMutation({
        mutationFn: (content) => register(content),
        onSuccess: () => {
            setConfirmed(true)
            setLoader(false)
        },
        onError: (error) => {
            const snackbarError = ResponseSnackbarErrorHandler('register', error)
            if (snackbarError) { setSnackbarObj(snackbarError) }
        }
    })
    const handleSubmit = (e) => {
        setLoader(true)
        e.preventDefault()
        registerMutation.mutate({
            displayName: profileFormValues.displayName,
            email: profileFormValues.email,
            password: profileFormValues.password,
            GDPR: profileFormValues.GDPR,
            CGU: profileFormValues.CGU,
            profile: profileFormValues.profile
        })
    }

    async function handleCheckUsernames() {
        const result = await checkUsernames({ displayName: profileFormValues.displayName, email: profileFormValues.email });
        if (result === 'displayName already exist') { setValueExist(1) }
        if (result === 'email already exist') { setValueExist(2) }
        if (result === 'displayName and email already exist') { setValueExist(3) }
        if (result === 'displayName and email are available') { setValueExist(0) }
    }

    function handlePasswordCopy() {
        setSnackbarObj({
            message: t("snackbar.copied"),
            status: 'success',
            timestamp: new Date().getTime()
        })
    }

    if (confirmed) {
        return (
            <RegisterConfirm />
        );
    };

    return (

        <div className='login-form'>
            <ThemeProvider theme={darkTheme}>
                <div className='register-form'>
                    <span className='login-form-titles'>{t('registerForm.subTitle')}</span>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                required
                                name="displayName"
                                label={t('registerForm.displayName')}
                                variant="outlined"
                                size="small"
                                onChange={handleInputChange}
                                onBlur={handleBlur}
                                value={profileFormValues.displayName}
                                error={
                                    (profileFormTouches.displayName && profileFormErrors.displayName) ||
                                    (profileFormValues.displayName && isValidDisplayName(profileFormValues.displayName) === false) ||
                                    valueExist === 1 || valueExist === 3 ||
                                    (profileFormValues.displayName.length > 50)
                                }
                                helperText={
                                    (profileFormValues.displayName && isValidDisplayName(profileFormValues.displayName) === false && t('registerForm.field-down-helper-displayNameRegex')) ||
                                    ((valueExist === 1 || valueExist === 3) && t('registerForm.field-down-helper-displayName')) ||
                                    (profileFormValues.displayName.length > 50 && t('registerForm.field-down-helper-displayNameTooLong'))
                                }
                                InputLabelProps={profileFormValues.displayName ? { shrink: true } : null}
                            />
                        </Grid>
                        <Grid item xs={12}  >
                            <TextField
                                fullWidth
                                required
                                name="email"
                                label={t('registerForm.email')}
                                variant="outlined"
                                size="small"
                                onChange={handleInputChange}
                                onBlur={handleBlur}
                                value={profileFormValues.email}
                                error={
                                    (profileFormTouches.email && profileFormErrors.email) ||
                                    (profileFormValues.email && isValidEmail(profileFormValues.email) === false) ||
                                    valueExist === 2 || valueExist === 3
                                }
                                helperText={
                                    (profileFormValues.email && isValidEmail(profileFormValues.email) === false && t('registerForm.field-down-helper-emailRegex')) ||
                                    ((valueExist === 2 || valueExist === 3) && t('registerForm.field-down-helper-email'))
                                }
                                InputLabelProps={profileFormValues.email ? { shrink: true } : null}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ClickAwayListener onClickAway={handleTooltipClose}>
                                <TextField
                                    fullWidth
                                    required
                                    name="password"
                                    type={showPassword ? 'text' : 'password'}
                                    label={t('registerForm.password')}
                                    variant="outlined"
                                    size="small"
                                    onChange={handleInputChange}
                                    onBlur={handleBlur}
                                    onFocus={handleTooltipOpen}
                                    value={profileFormValues.password}
                                    error={profileFormTouches.password && profileFormErrors.password}
                                    // helperText={profileFormTouches.password && profileFormErrors.password && t('registerForm.field-down-helper')}
                                    InputLabelProps={profileFormValues.password ? { shrink: true } : null}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">

                                                <CopyToClipboard text={profileFormValues.password}
                                                    onCopy={() => handlePasswordCopy()}
                                                >
                                                    <IconButton
                                                        title={t('button.label-PasswordCopy')}
                                                        aria-label={t('button.label-PasswordCopy')}
                                                    >
                                                        <RiFileCopyLine />
                                                    </IconButton>
                                                </CopyToClipboard>

                                                <IconButton
                                                    title={t('button.label-PasswordVisibility')}
                                                    aria-label={t('button.label-PasswordVisibility')}
                                                    onClick={handleClickShowPassword}
                                                >
                                                    {showPassword ? <RiEyeLine /> : <RiEyeOffLine />}
                                                </IconButton>
                                                <IconButton
                                                    title={t('button.label-PasswordGenerator')}
                                                    aria-label={t('button.label-PasswordGenerator')}
                                                    onClick={generatePassword}
                                                >
                                                    <RiRestartLine />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </ClickAwayListener>
                            {openPasswordTooltip ?
                                <div className='passwordTooltip-container'>
                                    <div className='passwordTooltip'>
                                        <h5>{t('registerForm.passwordPolicy-title')}</h5>
                                        <ul>
                                            <li>
                                                {passwordStrength.hasLowerCase
                                                    ? <RiCheckboxCircleLine color='var(--nhub-color-secondary-5)' />
                                                    : <RiInformationLine color='var(--nhub-color-secondary-6)' />
                                                } {t('registerForm.passwordPolicy-lowercase')}
                                            </li>
                                            <li>
                                                {passwordStrength.hasUpperCase
                                                    ? <RiCheckboxCircleLine color='var(--nhub-color-secondary-5)' />
                                                    : <RiInformationLine color='var(--nhub-color-secondary-6)' />
                                                } {t('registerForm.passwordPolicy-uppercase')}
                                            </li>
                                            <li>
                                                {passwordStrength.hasDigit
                                                    ? <RiCheckboxCircleLine color='var(--nhub-color-secondary-5)' />
                                                    : <RiInformationLine color='var(--nhub-color-secondary-6)' />
                                                } {t('registerForm.passwordPolicy-numeric')}
                                            </li>
                                            <li>
                                                {passwordStrength.hasSpecialChar
                                                    ? <RiCheckboxCircleLine color='var(--nhub-color-secondary-5)' />
                                                    : <RiInformationLine color='var(--nhub-color-secondary-6)' />
                                                } {t('registerForm.passwordPolicy-specialChar')}
                                            </li>
                                            <li>
                                                {passwordStrength.length
                                                    ? <RiCheckboxCircleLine color='var(--nhub-color-secondary-5)' />
                                                    : <RiInformationLine color='var(--nhub-color-secondary-6)' />
                                                } {t('registerForm.passwordPolicy-length')}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                : null
                            }
                        </Grid>
                    </Grid>
                </div>
                <div className='register-buttons-container'>
                    <div style={{display: "flex",alignItems: "center"}}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={profileFormValues.GDPR}
                                    onChange={handleChangeGDPR}
                                    sx={
                                        profileFormTouches.GDPR
                                            ? {
                                                color: "var(--mui-error)",
                                                // color: 'var(--nhub-color-secondary-1)',
                                                // '&.Mui-checked': { color: 'var(--nhub-color1)', },
                                                padding: '5px',
                                            }
                                            : {
                                                // color: 'var(--nhub-color2)',
                                                // '&.Mui-checked': { color: 'var(--nhub-color1)', },
                                                padding: '5px',
                                            }
                                    }
                                />
                            }
                            // label={t('registerForm.GDPR') + '*'}
                            sx={{ marginLeft: '-8px', marginRight: '0px' }}
                        />
                        <span>{t('registerForm.GDPR') + '*'}</span>
                    </div>
                    <div style={{display: "flex",alignItems: "center"}}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={profileFormValues.CGU}
                                    onChange={handleChangeCGU}
                                    sx={
                                        profileFormTouches.CGU
                                            ? {
                                                color: "var(--mui-error)",
                                                // color: 'var(--nhub-color-secondary-1)',
                                                // '&.Mui-checked': { color: 'var(--nhub-color1)', },
                                                padding: '5px',
                                            }
                                            : {
                                                // color: 'var(--nhub-color2)',
                                                // '&.Mui-checked': { color: 'var(--nhub-color1)', },
                                                padding: '5px',
                                            }
                                    }
                                />
                            }
                            // label={t('registerForm.CGU') + t('registerForm.CGULink') + '*'}
                            sx={{ marginLeft: '-8px', marginRight: '0px' }}
                        />
                        <span>
                            {t('registerForm.CGU')}
                            <span className='external-links'>
                                <a href={t('links.cgu-link')}
                                    title={t('links.cgu-link')} aria-label={t('links.cgu-link')}
                                    target="_blank" rel="noopener noreferrer">
                                    {t('registerForm.CGULink')}
                                </a>
                            </span>
                            {t('registerForm.and')}
                            <span className='external-links' >
                                <a href={t('links.privacyPolicy-link')}
                                    title={t('links.privacyPolicy-link')} aria-label={t('links.privacyPolicy-link')}
                                    target="_blank" rel="noopener noreferrer">
                                    {t('registerForm.privacyPolicy')}
                                </a>
                            </span>
                            *
                        </span>
                    </div>
                </div>
                <Button
                    variant="contained"
                    type="submit"
                    disabled={!isEnabled()}
                    onClick={(e) => { handleSubmit(e) }}
                    sx={{
                        color: 'white',
                        backgroundColor: "var(--button-background-8)",
                        textTransform: 'none',
                        fontSize: '14px', fontWeight: '400', padding: '6px 10px', marginRight: '10px',
                        borderRadius: '15px',
                        ':hover': { backgroundColor: "var(--button-background-hover-8)" }
                    }}
                >
                    {t('button.submit')}
                    {loader
                        ? <CircularProgress
                            size={24}
                            sx={{
                                color: "var(--button-background-6)",
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                            }}
                        />
                        : null
                    }
                </Button>
            </ThemeProvider>
            {snackbarObj?.message
                ? <ResponseSnackbar
                    status={snackbarObj.status}
                    message={snackbarObj.message}
                    responseCode={snackbarObj.responseCode}
                    timestamp={snackbarObj.timestamp}
                    request={snackbarObj.request}
                    failureCount={snackbarObj.failureCount}
                />
                : null}
        </div>
    );
};
export default RegisterForm;