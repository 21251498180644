import '../../styles/LoginPage.css'
import loginPageImage from '../../assets/images/loginPageImage.webp'
import loginPageImageSmall from '../../assets/images/loginPageImage-small.webp'

import { useTranslation } from "react-i18next";

import LoginForm from '../../components/LoginForm'
import LoginBanner from '../../components/LoginBanner'
import { useProgressiveImage } from '../../hooks/useProgressiveImage';

import { Routes, Route } from 'react-router-dom';
import RegisterForm from '../../components/RegisterForm';

function LoginPage() {
    const { t } = useTranslation();
    const imageLoaded = useProgressiveImage(loginPageImage)

    return (
        <div className='login-page'
            style={{
                backgroundColor: 'rgba(77, 99, 109, 0.5)',
                backgroundImage: `url(${loginPageImage})`
            }}
        >
            <div className="bg-fade" style={{
                backgroundImage: `url(${loginPageImageSmall})`,
                opacity: imageLoaded ? 0 : 1
            }}></div>
            <LoginBanner />
            <div className='login-content'>
                <Routes>
                    <Route path="/" element={<LoginForm />} />
                    <Route path="/register" element={<RegisterForm />} />
                    <Route path="*" element={<LoginForm />} />
                </Routes>
            </div>
            <div className='login-footer'>
                <a href={t('links.cgu-link')}
                    title={t('links.cgu-link')} aria-label={t('links.cgu-link')}
                    target="_blank" rel="noopener noreferrer">
                    {t('login.cgu')}
                </a>
                <a href={t('links.privacyPolicy-link')}
                    title={t('links.privacyPolicy-link')} aria-label={t('links.privacyPolicy-link')}
                    target="_blank" rel="noopener noreferrer">
                    {t('login.privacyPolicy')}
                </a>
            </div>
        </div>
    );
};
export default LoginPage;