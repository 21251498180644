import "./App.css";
import './styles/Header.css'
import watermark from "./assets/nybble-logo/Nybble-Logo-Full-Color.svg";

import { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";

import { Header, MainNav } from "@nybble-security/nybble-react-library";

import Error404 from "./components/Error404";
import ConfirmEmail from './components/ConfirmEmail'

import Overview from "./pages/Statistics/Overview";
import AnalystDashboard from "./pages/Statistics/AnalystDashboard";
import ScoringPage from "./pages/Statistics/ScoringPage";
import AlertsPage from "./pages/Alerts/AlertsPage";
import AlertSingle from "./pages/Alerts/AlertSingle";
import EditReport from "./pages/Hunts/EditReport";
import AdminPage from "./pages/Admin/AdminPage";
import SettingsPage from "./pages/ClientSettings/SettingsPage";
import ProfilePage from "./pages/User/ProfilePage";
import InvestigationsPage from "./pages/Investigations/InvestigationsPage";
import InvestigationSingle from "./pages/Investigations/InvestigationSingle";
import CreateWikiContribution from "./pages/Wikis/CreateWikiContribution";
import EditWikiContribution from "./pages/Wikis/EditWikiContribution";
import WikiPage from "./pages/Wikis/WikiPage";
import LoginPage from "./pages/Login/LoginPage";
import Campaigns from "./pages/Hunts/Campaigns";
import CampaignSingle from "./pages/Hunts/CampaignSingle";

import AdminCampaignsTable from "./pages/Admin/CampaignsTable";
import AdminCampaignSingle from "./pages/Admin/CampaignSingle";
import AdminHuntersSelect from "./pages/Admin/HuntersSelect";
import AdminPendingReportsTable from "./pages/Admin/PendingReportsTable";
import AdminPendingWikisTable from "./pages/Admin/PendingWikisTable";
import AdminValidateWiki from "./pages/Admin/ValidateWiki";
import AdminClientsTable from "./pages/Admin/ClientsTable";
import AdminClientSingle from "./pages/Admin/ClientSingle";
import AdminUsersTable from "./pages/Admin/UsersTable";
import AdminUserSingle from "./pages/Admin/UserSingle";
import AdminScoringPage from "./pages/Admin/UserScoringPage";

import InvestSummary from "./pages/Investigations/InvestSummary";
import InvestAlerts from "./pages/Investigations/InvestAlerts";
import InvestMitreAttack from "./pages/Investigations/InvestMitreAttack";
import InvestHistory from "./pages/Investigations/InvestHistory";
import InvestComments from "./pages/Investigations/InvestComments";

import ClientSettingsAgents from "./pages/ClientSettings/Agents";
import ClientSettingsConnectors from "./pages/ClientSettings/Connectors";

import CandidatePage from "./pages/Registration/CandidatePage";

import Loader from "./components/Loader";
import { ResponseSnackbar, ResponseSnackbarErrorHandler } from "./components/ResponseSnackbar";
import i18n from "./i18n";
import { Language } from "./data/i18n/enums/Language";
import { getUserMe, getAllUsers } from "./services/user.service";
import { getAllClient } from "./services/client.service";
import { getAllHuntCampaign } from "./services/huntCampaign.service";
import { UserInfosContext } from "./context/UserInfosContext";
import NavBarLinks from './components/NavBarLinks'
import { HeaderNavLinks } from './components/Header'

import "@nybble-security/nybble-react-library/dist/index.css";

const languageSelect = {
  i18n: i18n,
  Language: Language,
};


const App = () => {
  // used by login page and logout page to inform ROOT page that user state has changed.
  const { isAuthenticated, isLoading, getAccessTokenSilently, user, logout } = useAuth0();

  const [userInfos, setUserInfos] = useState(false);
  const [emailVerified, setEmailVerified] = useState(true);

  const [snackbarObj, setSnackbarObj] = useState();

  let localStorageLanguageInfo = JSON.parse(localStorage.getItem("handleLanguage"));

  useEffect(() => {
    if (isAuthenticated) {
      if (user?.email_verified === false) {
        setEmailVerified(false)
      }
    }
  }, [isAuthenticated, user?.email_verified])

  const {
    data: myUserInfo,
    isLoading: loadingMyUserInfo,
    error: errorMyUserInfo,
    failureReason: failureMyUserInfo,
    failureCount: failureCountMyUserInfo,
    isFetching: fetchingMyUserInfo,
  } = useQuery({
    enabled: !!getAccessTokenSilently && !!user?.email_verified, // ensures the query is only executed when these conditions are met.
    queryKey: ["myUserInfo"],
    queryFn: () => getAccessTokenSilently().then((token) => getUserMe(token)),
  });

  useEffect(() => {
    if (myUserInfo) {
      setUserInfos(myUserInfo);
    }
  }, [myUserInfo]);

  const {
    data: usersList,
    isLoading: loadingUsersList,
    error: errorUsersList,
    failureReason: failureUsersList,
    failureCount: failureCountUsersList,
    isFetching: fetchingUsersList,
  } = useQuery({
    enabled: !!getAccessTokenSilently && userInfos?.profile === "Admin", // ensures the query is only executed when getAccessTokenSilently is available.
    queryKey: ["usersList"],
    queryFn: () => getAccessTokenSilently().then((token) => getAllUsers(token)),
  });

  const {
    data: clientsList,
    isLoading: loadingClientsList,
    error: errorClientsList,
    failureReason: failureClientsList,
    failureCount: failureCountClientsList,
    isFetching: fetchingClientsList,
  } = useQuery({
    enabled: !!getAccessTokenSilently && userInfos?.profile === "Admin", // ensures the query is only executed when getAccessTokenSilently is available.
    queryKey: ["clientsList"],
    queryFn: () => getAccessTokenSilently().then((token) => getAllClient(token)),
  });

  const {
    data: huntCampaignsList,
    isLoading: loadingHuntCampaignsList,
    error: errorHuntCampaignsList,
    failureReason: failureHuntCampaignsList,
    failureCount: failureCountHuntCampaignsList,
    isFetching: fetchingHuntCampaignsList,
  } = useQuery({
    enabled: !!getAccessTokenSilently && userInfos?.profile === "Admin", // ensures the query is only executed when getAccessTokenSilently is available.
    queryKey: ["huntCampaignsList"],
    queryFn: () => getAccessTokenSilently().then((token) => getAllHuntCampaign(token)),
  });

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      /* if connected ? */
      if (userInfos?.language !== "Browser") {
        if (userInfos.language === "fr") {
          i18n.changeLanguage(Language.FR);
        } else if (userInfos.language === "en") {
          i18n.changeLanguage(Language.EN);
        }
      } else if (localStorageLanguageInfo) {
        if (localStorageLanguageInfo === "fr") {
          i18n.changeLanguage(Language.FR);
        } else if (localStorageLanguageInfo === "en") {
          i18n.changeLanguage(Language.EN);
        }
      }
    }
    return () => (mounted = false);
  }, [localStorageLanguageInfo, userInfos]);

  useEffect(() => {
    const snackbarUserInfo = ResponseSnackbarErrorHandler("myUserInfo", errorMyUserInfo, failureMyUserInfo, failureCountMyUserInfo);
    if (snackbarUserInfo) {
      setSnackbarObj(snackbarUserInfo);
    }

    if(errorMyUserInfo?.response?.status === 401){
      // user does not exist anymore but its Auth0 session hadn't expired yet
      logout()
    }

    const snackbarUserList = ResponseSnackbarErrorHandler("usersList", errorUsersList, failureUsersList, failureCountUsersList);
    if (snackbarUserList) {
      setSnackbarObj(snackbarUserList);
    }

    const snackbarClientsList = ResponseSnackbarErrorHandler("clientsList", errorClientsList, failureClientsList, failureCountClientsList);
    if (snackbarClientsList) {
      setSnackbarObj(snackbarClientsList);
    }

    const snackbarHuntCampaignsList = ResponseSnackbarErrorHandler("huntCampaignsList", errorHuntCampaignsList, failureHuntCampaignsList, failureCountHuntCampaignsList);
    if (snackbarHuntCampaignsList) {
      setSnackbarObj(snackbarHuntCampaignsList);
    }
  }, [logout,errorClientsList, errorHuntCampaignsList, errorMyUserInfo, errorUsersList, failureClientsList, failureCountClientsList, failureCountHuntCampaignsList, failureCountMyUserInfo, failureCountUsersList, failureHuntCampaignsList, failureMyUserInfo, failureUsersList]);

  /* Loader when query is in InitialLoading and isFetching (to confirm that is enabled) */
  if (isLoading || (loadingMyUserInfo && fetchingMyUserInfo)) {
    return <Loader />;
  }

  if (!isAuthenticated) {
    return (
      <UserInfosContext.Provider value={{ userInfos, setUserInfos }}>
        <LoginPage />
      </UserInfosContext.Provider>
    );
  }

  if (isAuthenticated && !emailVerified) {
    return (
      <UserInfosContext.Provider value={{ userInfos, setUserInfos }}>
        <ConfirmEmail />
        </UserInfosContext.Provider>
      )
  }

  return !userInfos ? (
    <Loader />
  ) : (
    <UserInfosContext.Provider
      value={{
        userInfos,
        setUserInfos,
        snackbarObj,
        setSnackbarObj,
      }}
    >
      <Header platformName="Hub" languageSelect={languageSelect} HeaderNavLinks={HeaderNavLinks} NavBarLinks={NavBarLinks} />
      <MainNav NavBarLinks={NavBarLinks} />
      <div id="mainContent">
        {(() => {
          switch (userInfos.profile) {
            case "Admin":
              return (
                <Routes>
                  <Route path="/profile" element={<ProfilePage />} />
                  <Route path="/statistics" element={<Overview />} />
                  <Route path="/alerts" element={<AlertsPage />} />
                  <Route path="/alerts/:alertId" element={<AlertSingle />} />
                  <Route path="/investigations" element={<InvestigationsPage />} />
                  <Route path="/investigations/:investId" element={<InvestigationSingle />}>
                    <Route path="" element={<InvestSummary />} />
                    <Route path="InvestAlerts" element={<InvestAlerts />} />
                    <Route path="AlertSingle" element={<AlertSingle />} />
                    <Route path="InvestMitreAttack" element={<InvestMitreAttack />} />
                    <Route path="InvestHistory" element={<InvestHistory />} />
                    <Route path="InvestComments" element={<InvestComments />} />
                  </Route>
                  <Route path="/campaigns" element={<Campaigns />} />
                  <Route path="/campaigns/:campaignId" element={<CampaignSingle />} />
                  <Route path="/campaigns/:campaignId/report/:reportId" element={<EditReport />} />
                  <Route path="/admin" element={<AdminPage />}>
                    <Route path="" element={<Navigate to="/admin/users" replace />} />
                    <Route path="users" element={<AdminUsersTable usersList={usersList} clientsList={clientsList} loadingData={(loadingUsersList && fetchingUsersList) || (loadingClientsList && fetchingClientsList)} />} />
                    <Route path="users/:userId" element={<AdminUserSingle usersList={usersList} clientsList={clientsList} loadingData={(loadingUsersList && fetchingUsersList) || (loadingClientsList && fetchingClientsList)} />} />
                    <Route path="users/:userId/statistics/scoring" element={<AdminScoringPage />} />

                    <Route path="hunters" element={<AdminHuntersSelect huntCampaignsList={huntCampaignsList} usersList={usersList} loadingData={(loadingUsersList && fetchingUsersList) || (loadingHuntCampaignsList && fetchingHuntCampaignsList)} />} />
                    <Route path="pendingReports" element={<AdminPendingReportsTable huntCampaignsList={huntCampaignsList} loadingData={loadingHuntCampaignsList && fetchingHuntCampaignsList} />} />
                    <Route path="pendingWikis" element={<AdminPendingWikisTable />} />
                    <Route path="pendingWikis/:contributionId" element={<AdminValidateWiki />} />
                    <Route path="clients" element={<AdminClientsTable clientsList={clientsList} loadingData={loadingClientsList && fetchingClientsList} />} />
                    <Route path="clients/:clientId" element={<AdminClientSingle usersList={usersList} loadingData={loadingUsersList && fetchingUsersList} />} />
                    <Route path="campaign" element={<AdminCampaignsTable huntCampaignsList={huntCampaignsList} clientsList={clientsList} loadingData={(loadingClientsList && fetchingClientsList) || (loadingHuntCampaignsList && fetchingHuntCampaignsList)} />} />
                    <Route path="campaign/:campaignId" element={<AdminCampaignSingle />} />
                    <Route path="campaign/report/:reportId" element={<EditReport />} />
                  </Route>
                  <Route path="/wikis" element={<WikiPage />} />
                  <Route path="/wikis/contribution/:contributionId" element={<EditWikiContribution />} />
                  <Route path="/wikis/:wikiId" element={<CreateWikiContribution />} />
                  <Route path="*" element={<Navigate to="/investigations" replace />} />
                </Routes>
              );
            case "Client":
              return (
                <Routes>
                  <Route path="/profile" element={<ProfilePage />} />
                  <Route path="/statistics" element={<Overview />} />
                  <Route path="/investigations" element={<InvestigationsPage />} />
                  <Route path="/investigations/:investId" element={<InvestigationSingle />}>
                    <Route path="" element={<InvestSummary />} />
                    <Route path="InvestAlerts" element={<InvestAlerts />} />
                    <Route path="AlertSingle" element={<AlertSingle />} />
                    <Route path="InvestMitreAttack" element={<InvestMitreAttack />} />
                    <Route path="InvestHistory" element={<InvestHistory />} />
                    <Route path="InvestComments" element={<InvestComments />} />
                  </Route>
                  <Route path="/campaigns" element={<Campaigns />} />
                  <Route path="/campaigns/:campaignId" element={<CampaignSingle />} />
                  <Route path="/settings" element={<SettingsPage />}>
                    <Route path="" element={<Navigate to="/settings/agents" replace />} />
                    <Route path="agents" element={<ClientSettingsAgents />} />
                    <Route path="connectors" element={<ClientSettingsConnectors />} />
                  </Route>
                  <Route path="*" element={<Navigate to="investigations" replace />} />
                </Routes>
              );
            case "Hunter":
              return (
                <Routes>
                  <Route path="/profile" element={<ProfilePage />} />
                  <Route path="/campaigns" element={<Campaigns />} />
                  <Route path="/campaigns/:campaignId" element={<CampaignSingle />} />
                  <Route path="/campaigns/:campaignId/report/:reportId" element={<EditReport />} />
                  <Route path="/registration/*" element={<CandidatePage />} />
                  <Route path="*" element={<Navigate to="campaigns" replace />} />
                </Routes>
              );
            case "Hunter-Analyst":
              return (
                <Routes>
                  <Route path="/statistics" element={<AnalystDashboard />} />
                  <Route path="/statistics/scoring" element={<ScoringPage />} />
                  <Route path="/profile" element={<ProfilePage />} />
                  <Route path="/campaigns" element={<Campaigns />} />
                  <Route path="/campaigns/:campaignId" element={<CampaignSingle />} />
                  <Route path="/campaigns/:campaignId/report/:reportId" element={<EditReport />} />
                  <Route path="/alerts" element={<AlertsPage />} />
                  <Route path="/alerts/:alertId" element={<AlertSingle />} />
                  <Route path="/wikis" element={<WikiPage />} />
                  <Route path="/wikis/contribution/:contributionId" element={<EditWikiContribution />} />
                  <Route path="/wikis/:wikiId" element={<CreateWikiContribution />} />
                  <Route path="/registration/*" element={<CandidatePage />} />
                  <Route path="*" element={<Navigate to="alerts" replace />} />
                </Routes>
              );
            case "Analyst_L1":
              return (
                <Routes>
                  <Route path="/statistics" element={<AnalystDashboard />} />
                  <Route path="/statistics/scoring" element={<ScoringPage />} />
                  <Route path="/profile" element={<ProfilePage />} />
                  <Route path="/alerts" element={<AlertsPage />} />
                  <Route path="/alerts/:alertId" element={<AlertSingle />} />
                  <Route path="/wikis" element={<WikiPage />} />
                  <Route path="/wikis/contribution/:contributionId" element={<EditWikiContribution />} />
                  <Route path="/wikis/:wikiId" element={<CreateWikiContribution />} />
                  <Route path="/registration/*" element={<CandidatePage />} />
                  <Route path="*" element={<Navigate to="alerts" replace />} />
                </Routes>
              );
            case "Analyst_L2":
              return (
                <Routes>
                  <Route path="/profile" element={<ProfilePage />} />
                  <Route path="/alerts" element={<AlertsPage />} />
                  <Route path="/alerts/:alertId" element={<AlertSingle />} />
                  <Route path="/investigations" element={<InvestigationsPage />} />
                  <Route path="/investigations/:investId" element={<InvestigationSingle />}>
                    <Route path="" element={<InvestSummary />} />
                    <Route path="InvestAlerts" element={<InvestAlerts />} />
                    <Route path="AlertSingle" element={<AlertSingle />} />
                    <Route path="InvestMitreAttack" element={<InvestMitreAttack />} />
                    <Route path="InvestHistory" element={<InvestHistory />} />
                    <Route path="InvestComments" element={<InvestComments />} />
                  </Route>
                  <Route path="/wikis" element={<WikiPage />} />
                  <Route path="/wikis/contribution/:contributionId" element={<EditWikiContribution />} />
                  <Route path="/wikis/:wikiId" element={<CreateWikiContribution />} />
                  <Route path="*" element={<Navigate to="investigations" replace />} />
                </Routes>
              );
            case "Candidate":
              return (
                <Routes>
                  <Route path="/registration/*" element={<CandidatePage />} />
                  <Route path="/profile" element={<ProfilePage />} />
                  <Route path="*" element={<Navigate to="registration" replace />} />
                </Routes>
              );
            default:
              return (
                <Routes>
                  <Route path="*" element={<Error404 />} />
                </Routes>
              );
          }
        })()}
        <img src={watermark} alt="watermark" className="watermark" />
        {snackbarObj?.message ? <ResponseSnackbar status={snackbarObj.status} message={snackbarObj.message} responseCode={snackbarObj.responseCode} timestamp={snackbarObj.timestamp} request={snackbarObj.request} failureCount={snackbarObj.failureCount} /> : null}
      </div>
    </UserInfosContext.Provider>
  );
};

export default App;
