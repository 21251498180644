import React, { useState, useEffect, useContext } from "react";

import '../../styles/InfoPages.css'

import moment from 'moment';
import { useTranslation } from "react-i18next";
import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import MuiPhoneInput from 'mui-phone-number';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Grid, TextField, Select, MenuItem, Button, Skeleton } from '@mui/material';

import { RiSave2Line } from 'react-icons/ri';

import { putMyPersonalInfo, getMyIdentityProof, postMyIdentityProof } from '../../services/personalInfo.service';
import { countriesList } from '../../data/countries';
import { civilityList } from '../../data/civilityList';
import { UserInfosContext } from '../../context/UserInfosContext';

import DownloadButton from '../../components/DownloadButton';
import UploadButton from '../../components/UploadButton';
import { ResponseSnackbarErrorHandler } from "../../components/ResponseSnackbar";

function PersonalInfoPage({ personalInfos, loadingData }) {

    const { t } = useTranslation();
    const { getAccessTokenSilently } = useAuth0();
    const queryClient = useQueryClient()
    const { setSnackbarObj } = useContext(UserInfosContext);

    const [profileFormValues, setProfileFormValues] = useState({})
    const [birthDateFormValue, setBirthDateFormValue] = useState(moment(''));

    const [fileName, setFileName] = useState()

    // les personalInfos arrivent en props -> pas prêtes -> useEffect
    useEffect(() => {
        if (personalInfos) {
            setProfileFormValues(personalInfos);
            setBirthDateFormValue(moment(personalInfos.birthDate))
        }

    }, [personalInfos])

    // validation failed indicators
    const [profileFormErrors, setProfileFormErrors] = useState({
        civility: false,
        lastName: false,
        firstName: false,
        birthDate: false,
        nationality: false,
        phoneNumber: false,
        address: false,
        additionalAddress: false,
        city: false,
        postalCode: false,
        country: false,
        identity_proof: false,
    });

    // has user done at least one thing on the field (to avoid displaying an error at 1st form display)
    const [profileFormTouches, setProfileFormTouches] = useState({
        civility: false,
        lastName: false,
        firstName: false,
        birthDate: false,
        nationality: false,
        phoneNumber: false,
        address: false,
        additionalAddress: false,
        city: false,
        postalCode: false,
        country: false,
        identity_proof: false
    });

    // update the react state with user input
    const handleInputChange = e => {
        const { name, value } = e.target
        setProfileFormValues({ ...profileFormValues, [name]: value });
    }
    // update the react state with user input
    const handleInputChangePhone = value => {
        const result = value.replace(/\s/g, "")
        setProfileFormValues({ ...profileFormValues, phoneNumber: result });
    }

    // update the validation infos when user leaves the field (used only on fields requiring validation)
    const handleBlur = e => {
        const { name,
            // value
        } = e.target
        profileFormValues[name] ? setProfileFormErrors({ ...profileFormErrors, [name]: false }) : setProfileFormErrors({ ...profileFormErrors, [name]: true });
        setProfileFormTouches({ ...profileFormTouches, [name]: true });
    }

    // set DatePicker's value to form's values before submitting
    useEffect(() => {
        if (birthDateFormValue._d && birthDateFormValue._isValid) {
            setProfileFormValues(profileFormValues => ({ ...profileFormValues, birthDate: (birthDateFormValue._d).toISOString() }));
        }
    }, [birthDateFormValue])

    // identity proof
    useEffect(() => {
        if (fileName) {
            setProfileFormValues(profileFormValues => ({ ...profileFormValues, identity_proof: fileName }));
        }
    }, [fileName])

    // submit button: enabled only when everything is alright
    function isEnabled() {
        return (
            (profileFormValues.civility && profileFormValues.lastName && profileFormValues.birthDate && profileFormValues.nationality && profileFormValues.phoneNumber && profileFormValues.address && profileFormValues.city && profileFormValues.postalCode && profileFormValues.country && profileFormValues.identity_proof)
            &&
            (!profileFormErrors.civility && !profileFormErrors.lastName && !profileFormErrors.birthDate && !profileFormErrors.nationality && !profileFormErrors.phoneNumber && !profileFormErrors.address && !profileFormErrors.city && !profileFormErrors.postalCode && !profileFormErrors.country && !profileFormErrors.identity_proof)
        )
    }

    const putMyPersonalInfoMutation = useMutation({
        enabled: !!getAccessTokenSilently, // ensures the query is only executed when these conditions are met.
        mutationFn: () => getAccessTokenSilently().then((token) => putMyPersonalInfo(token, profileFormValues)),
        onSuccess: (data) => {
            queryClient.setQueryData(['myPersonalInfo'], data) //update query data 
            setSnackbarObj({
                message: t("snackbar.saved"),
                status: 'success',
                timestamp: new Date().getTime()
            })
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
        },
        onError: (error) => {
            const snackbarError = ResponseSnackbarErrorHandler('myPersonalInfo', error)
            if (snackbarError) { setSnackbarObj(snackbarError) }
        }
    })

    const handleSubmit = (e) => {
        e.preventDefault()
        putMyPersonalInfoMutation.mutate()
    }

    return (
        <div className='information-form'>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} marginBottom={'10px'}>
                    <h3>{t('welcomeView.personalInfo-title')}</h3>
                    <p>{t('welcomeView.personalInfo-text')}</p>
                </Grid>
                <Grid item md={5}>
                    <div className="information-form-title">
                        <span>
                            {t('personalInfoPage.civility')}
                            <sup>*</sup>
                        </span>
                    </div>
                </Grid>
                <Grid item xs={12} md={7}>
                    {loadingData
                        ? <Skeleton variant="rounded" height={'40px'} />
                        : <Select
                            fullWidth
                            size="small"
                            required
                            name="civility"
                            // label={t('personalInfoPage.civility')}
                            variant="outlined"
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            value={profileFormValues.civility || ''}
                            error={profileFormTouches.civility && profileFormErrors.civility}
                            // helperText={profileFormTouches.civility && profileFormErrors.civility && t('personalInfoPage.field-down-helper')}
                            sx={{
                                textAlign: 'start'
                            }}
                        >
                            {civilityList.map((option, i) => (
                                <MenuItem key={i} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    }
                </Grid>
                <Grid item md={5}>
                    <div className="information-form-title">
                        <span>
                            {t('personalInfoPage.firstName')}
                            <sup>*</sup>
                        </span>
                    </div>
                </Grid>
                <Grid item xs={12} md={7}>
                    {loadingData
                        ? <Skeleton variant="rounded" height={'40px'} />
                        : <TextField
                            fullWidth
                            size="small"
                            required
                            name="firstName"
                            // label={t('personalInfoPage.firstName')}
                            variant="outlined"
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            value={profileFormValues.firstName || ''}
                            error={profileFormTouches.firstName && profileFormErrors.firstName}
                        // helperText={profileFormTouches.firstName && profileFormErrors.firstName && t('personalInfoPage.field-down-helper')}
                        // InputLabelProps={profileFormValues.firstName ? { shrink: true } : null}
                        />
                    }
                </Grid>
                <Grid item md={5}>
                    <div className="information-form-title">
                        <span>
                            {t('personalInfoPage.lastName')}
                            <sup>*</sup>
                        </span>
                    </div>
                </Grid>
                <Grid item xs={12} md={7}>
                    {loadingData
                        ? <Skeleton variant="rounded" height={'40px'} />
                        : <TextField
                            fullWidth
                            size="small"
                            required
                            name="lastName"
                            // label={t('personalInfoPage.lastName')}
                            variant="outlined"
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            value={profileFormValues.lastName || ''}
                            error={profileFormTouches.lastName && profileFormErrors.lastName}
                        // helperText={profileFormTouches.lastName && profileFormErrors.lastName && t('personalInfoPage.field-down-helper')}
                        // InputLabelProps={profileFormValues.lastName ? { shrink: true } : null}
                        />
                    }
                </Grid>
                <Grid item md={5}>
                    <div className="information-form-title">
                        <span>
                            {t('personalInfoPage.birthDate')}
                            <sup>*</sup>
                        </span>
                    </div>
                </Grid>
                <Grid item xs={12} md={7}>
                    {loadingData
                        ? <Skeleton variant="rounded" height={'40px'} />
                        : <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                format="DD/MM/YYYY"
                                openTo="year"
                                views={['year', 'month', 'day']}
                                disableFuture
                                // label="Basic date picker"
                                value={birthDateFormValue}
                                onChange={(newValue) => {
                                    setBirthDateFormValue(newValue)
                                }}
                                // minDate={moment(minEndDate)}
                                // renderInput={(params) => <TextField {...params} />}
                                slotProps={{ textField: { fullWidth: true, size: 'small' } }}
                                InputProps={{
                                    sx: {
                                        "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                                        "& .MuiSvgIcon-root": { color: "var(--nhub-color1)" },
                                        "& .MuiButtonBase-root": { padding: "0" },
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    }
                </Grid>
                <Grid item md={5}>
                    <div className="information-form-title">
                        <span>
                            {t('personalInfoPage.nationality')}
                            <sup>*</sup>
                        </span>
                    </div>
                </Grid>
                <Grid item xs={12} md={7}>
                    {loadingData
                        ? <Skeleton variant="rounded" height={'40px'} />
                        : <Select
                            fullWidth
                            size="small"
                            name="nationality"
                            //label={t('personalInfoPage.nationality')}
                            variant="outlined"
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            value={profileFormValues.nationality || ''}
                            error={profileFormTouches.nationality && profileFormErrors.nationality}
                            // helperText={profileFormTouches.nationality && profileFormErrors.nationality && t('personalInfoPage.field-down-helper')}
                            sx={{
                                textAlign: 'start'
                            }}
                        >
                            {countriesList.map((option, i) => (
                                <MenuItem key={i} value={option.nationality}>
                                    {option.nationality}
                                </MenuItem>
                            ))}
                        </Select>
                    }
                </Grid>
                <Grid item md={5}>
                    <div className="information-form-title">
                        <span>
                            {t('personalInfoPage.phoneNumber')}
                            <sup>*</sup>
                        </span>
                    </div>
                </Grid>
                <Grid item xs={12} md={7}>
                    {loadingData
                        ? <Skeleton variant="rounded" height={'40px'} />
                        : <MuiPhoneInput
                            defaultCountry='fr'
                            fullWidth
                            size="small"
                            required
                            // type="tel"
                            name="phoneNumber"
                            variant="outlined"
                            // onChange={handleInputChange}
                            onChange={handleInputChangePhone}
                            onBlur={handleBlur}
                            value={profileFormValues.phoneNumber || ''}
                            error={profileFormTouches.phoneNumber && profileFormErrors.phoneNumber}
                        // helperText={profileFormTouches.phoneNumber && profileFormErrors.phoneNumber && t('personalInfoPage.field-down-helper')}
                        />
                    }
                </Grid>
                <Grid item md={5}>
                    <div className="information-form-title">
                        <span>
                            {t('personalInfoPage.address')}
                            <sup>*</sup>
                        </span>
                    </div>
                </Grid>
                <Grid item xs={12} md={7}>
                    {loadingData
                        ? <Skeleton variant="rounded" height={'40px'} />
                        : <TextField
                            fullWidth
                            size="small"
                            required
                            name="address"
                            // label={t('personalInfoPage.address')}
                            variant="outlined"
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            value={profileFormValues.address || ''}
                            error={profileFormTouches.address && profileFormErrors.address}
                        // helperText={profileFormTouches.address && profileFormErrors.address && t('personalInfoPage.field-down-helper')}
                        // InputLabelProps={profileFormValues.address ? { shrink: true } : null}
                        />
                    }
                </Grid>
                <Grid item md={5}>
                    <div className="information-form-title">
                        <span>
                            {t('personalInfoPage.additionalAddress')}
                        </span>
                        <span className="information-form-title-optional">
                            {t('personalInfoPage.optional')}
                        </span>
                    </div>
                </Grid>
                <Grid item xs={12} md={7}>
                    {loadingData
                        ? <Skeleton variant="rounded" height={'40px'} />
                        : <TextField
                            fullWidth
                            size="small"
                            name="additionalAddress"
                            // label={t('personalInfoPage.additionalAddress')}
                            variant="outlined"
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            value={profileFormValues.additionalAddress || ''}
                        // error={profileFormTouches.additionalAddress && profileFormErrors.additionalAddress}
                        // helperText={profileFormTouches.additionalAddress && profileFormErrors.additionalAddress && t('personalInfoPage.field-down-helper')}
                        // InputLabelProps={profileFormValues.additionalAddress ? { shrink: true } : null}
                        />
                    }
                </Grid>
                <Grid item md={5}>
                    <div className="information-form-title">
                        <span>
                            {t('personalInfoPage.city')}
                            <sup>*</sup>
                        </span>
                    </div>
                </Grid>
                <Grid item xs={12} md={7}>
                    {loadingData
                        ? <Skeleton variant="rounded" height={'40px'} />
                        : <TextField
                            fullWidth
                            size="small"
                            required
                            name="city"
                            // label={t('personalInfoPage.city')}
                            variant="outlined"
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            value={profileFormValues.city || ''}
                            error={profileFormTouches.city && profileFormErrors.city}
                        // helperText={profileFormTouches.city && profileFormErrors.city && t('personalInfoPage.field-down-helper')}
                        // InputLabelProps={profileFormValues.city ? { shrink: true } : null}
                        />
                    }
                </Grid>
                <Grid item md={5}>
                    <div className="information-form-title">
                        <span>
                            {t('personalInfoPage.postalCode')}
                            <sup>*</sup>
                        </span>
                    </div>
                </Grid>
                <Grid item xs={12} md={7}>
                    {loadingData
                        ? <Skeleton variant="rounded" height={'40px'} />
                        : <TextField
                            fullWidth
                            size="small"
                            required
                            name="postalCode"
                            // label={t('personalInfoPage.postalCode')}
                            variant="outlined"
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            value={profileFormValues.postalCode || ''}
                            error={profileFormTouches.postalCode && profileFormErrors.postalCode}
                        // helperText={profileFormTouches.postalCode && profileFormErrors.postalCode && t('personalInfoPage.field-down-helper')}
                        // InputLabelProps={profileFormValues.postalCode ? { shrink: true } : null}
                        />
                    }
                </Grid>
                <Grid item md={5}>
                    <div className="information-form-title">
                        <span>
                            {t('personalInfoPage.country')}
                            <sup>*</sup>
                        </span>
                    </div>
                </Grid>
                <Grid item xs={12} md={7}>
                    {loadingData
                        ? <Skeleton variant="rounded" height={'40px'} />
                        : <Select
                            fullWidth
                            size="small"
                            required
                            name="country"
                            // label={t('personalInfoPage.country')}
                            variant="outlined"
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            value={profileFormValues.country || ''}
                            error={profileFormTouches.country && profileFormErrors.country}
                            // helperText={profileFormTouches.country && profileFormErrors.country && t('personalInfoPage.field-down-helper')}
                            sx={{
                                textAlign: 'start'
                            }}
                        >
                            {countriesList.map((option, i) => (
                                <MenuItem key={i} value={option.en_short_name}>
                                    {option.en_short_name}
                                </MenuItem>
                            ))}
                        </Select>
                    }
                </Grid>
                <Grid item md={5}>
                    <div className="information-form-title">
                        <span>
                            {t('personalInfoPage.identity')}
                            <sup>*</sup>
                        </span>
                    </div>
                </Grid>
                <Grid item xs={12} md={7}>
                    <Grid item xs={12}
                        container
                        direction="row"
                        alignItems="center"
                        gap={1}
                    >
                        {profileFormValues.identity_proof
                            ? <DownloadButton
                                downloadParameters={{}}
                                downloadFunction={getMyIdentityProof}
                                label={profileFormValues.identity_proof}
                                fileName={profileFormValues.identity_proof}
                            />
                            : null
                        }
                        <UploadButton
                            downloadParameters={{}}
                            downloadFunction={postMyIdentityProof}
                            label={profileFormValues.identity_proof ? t('button.overwrite') : t('button.upload')}
                            setFileName={setFileName}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} textAlign={'right'} marginTop={"30px"}>
                    <Button
                        variant="contained"
                        disabled={!isEnabled()}
                        startIcon={<RiSave2Line />}
                        sx={{
                            backgroundColor: "var(--button-background-6)",
                            textTransform: 'none',
                            fontSize: '14px', fontWeight: '400', padding: '6px 10px',
                            ':hover': { backgroundColor: "var(--button-background-hover-6)" }
                        }}
                        onClick={(e) => { handleSubmit(e) }}
                    >
                        {t('button.save')}
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
};
export default PersonalInfoPage;