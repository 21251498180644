import React, { useState, useEffect, useContext } from "react";

import '../../styles/InfoPages.css'

import { useTranslation } from "react-i18next";
import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Grid, TextField, Button, Skeleton } from '@mui/material';

import { RiSave2Line } from 'react-icons/ri';

import { ResponseSnackbarErrorHandler } from '../../components/ResponseSnackbar';
import { putMyExpInfo, getMyResume, postMyResume } from '../../services/expInfo.service';
import { UserInfosContext } from '../../context/UserInfosContext';

import DownloadButton from '../../components/DownloadButton';
import UploadButton from '../../components/UploadButton';


function validURL(str) {
    if (str) {
        var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(str);
    } else return false
}

function ExpInfoPage({ expInfos, loadingData }) {

    const { t } = useTranslation();
    const { getAccessTokenSilently } = useAuth0();
    const queryClient = useQueryClient()
    const { setSnackbarObj } = useContext(UserInfosContext);

    // const [loader, setLoader] = useState(false);
    const [profileFormValues, setProfileFormValues] = useState(expInfos || {})
    const [fileName, setFileName] = useState()


    // validation failed indicators
    const [profileFormErrors, setProfileFormErrors] = useState({
        lastPosition: false,
        yearsExp: false,
        diploma: false,
        linkedin: false,
        resume: false,
        references: false,
    });

    // has user done at least one thing on the field (to avoid displaying an error at 1st form display)
    const [profileFormTouches, setProfileFormTouches] = useState({
        lastPosition: false,
        yearsExp: false,
        diploma: false,
        linkedin: false,
        resume: false,
        references: false,
    });

    // les expInfops arrivent en props -> pas prêtes -> useEffect
    useEffect(() => {
        if (expInfos) {
            setProfileFormValues(expInfos);
        }

    }, [expInfos])

    // update the react state with user input
    const handleInputChange = e => {
        const { name, value } = e.target
        setProfileFormValues({ ...profileFormValues, [name]: value });
    }

    // update the validation infos when user leaves the field (used only on fields requiring validation)
    const handleBlur = e => {
        const { name,
            // value
        } = e.target
        profileFormValues[name] ? setProfileFormErrors({ ...profileFormErrors, [name]: false }) : setProfileFormErrors({ ...profileFormErrors, [name]: true });
        setProfileFormTouches({ ...profileFormTouches, [name]: true });
    }


    useEffect(() => {
        if (fileName) {
            setProfileFormValues(profileFormValues => ({ ...profileFormValues, resume: fileName }));
        }
    }, [fileName])

    // submit button: enabled only when everything is alright
    function isEnabled() {
        return (
            (profileFormValues.lastPosition && profileFormValues.yearsExp && profileFormValues.diploma)
            &&
            (validURL(profileFormValues.linkedin) || profileFormValues.resume)
            &&
            (!profileFormErrors.lastPosition && !profileFormErrors.yearsExp && !profileFormErrors.diploma && !profileFormErrors.linkedin)
        )
    }


    const putMyExpInfoMutation = useMutation({
        enabled: !!getAccessTokenSilently, // ensures the query is only executed when these conditions are met.
        mutationFn: () => getAccessTokenSilently().then((token) => putMyExpInfo(token, profileFormValues)),
        onSuccess: (data) => {
            queryClient.setQueryData(['myExpInfo'], data) //update query data 
            setSnackbarObj({
                message: t("snackbar.saved"),
                status: 'success',
                timestamp: new Date().getTime()
            })
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
        },
        onError: (error) => {
            const snackbarError = ResponseSnackbarErrorHandler('myExpInfo', error)
            if (snackbarError) { setSnackbarObj(snackbarError) }
        }
    })

    const handleSubmit = (e) => {
        e.preventDefault()
        putMyExpInfoMutation.mutate()
    }

    return (
        <div className='information-form'>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            // sx={{
            //     width: '100% !important',
            //     marginLeft: '0 !important',
            // }}
            >
                <Grid item container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} marginBottom={'10px'}>
                        <h3>{t('welcomeView.expInfo-title')}</h3>
                        <p>{t('welcomeView.expInfo-text')}</p>
                    </Grid>
                    <Grid item md={5}>
                        <div className="information-form-title">
                            <span>
                                {t('expInfoPage.lastPosition')}
                                <sup>*</sup>
                            </span>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        {loadingData
                            ? <Skeleton variant="rounded" height={'40px'} />
                            : <TextField
                                fullWidth
                                size="small"
                                required
                                name="lastPosition"
                                // label={t('expInfoPage.lastPosition')}
                                variant="outlined"
                                onChange={handleInputChange}
                                onBlur={handleBlur}
                                value={profileFormValues.lastPosition}
                                error={profileFormTouches.lastPosition && profileFormErrors.lastPosition}
                            // helperText={profileFormTouches.lastPosition && profileFormErrors.lastPosition && t('expInfoPage.field-down-helper')}
                            // InputLabelProps={profileFormValues.lastPosition ? { shrink: true } : null}
                            />
                        }
                    </Grid>
                    <Grid item xs={6} md={2}></Grid>
                    <Grid item md={7}>
                        <div className="information-form-title">
                            <span>
                                {t('expInfoPage.yearsExp')}
                                <sup>*</sup>
                            </span>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        {loadingData
                            ? <Skeleton variant="rounded" height={'40px'} />
                            : <TextField
                                fullWidth
                                size="small"
                                required
                                name="yearsExp"
                                type="number"
                                // label={t('expInfoPage.yearsExp')}
                                variant="outlined"
                                onChange={handleInputChange}
                                onBlur={handleBlur}
                                value={profileFormValues.yearsExp}
                                error={profileFormTouches.yearsExp && profileFormErrors.yearsExp}
                            // helperText={profileFormTouches.yearsExp && profileFormErrors.yearsExp && t('expInfoPage.field-down-helper')}
                            // InputLabelProps={profileFormValues.yearsExp ? { shrink: true } : null}
                            />
                        }
                    </Grid>
                    <Grid item md={5}>
                        <div className="information-form-title">
                            <span>
                                {t('expInfoPage.diploma')}
                                <sup>*</sup>
                            </span>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        {loadingData
                            ? <Skeleton variant="rounded" height={'40px'} />
                            : <TextField
                                fullWidth
                                size="small"
                                required
                                name="diploma"
                                // label={t('expInfoPage.diploma')}
                                variant="outlined"
                                onChange={handleInputChange}
                                onBlur={handleBlur}
                                value={profileFormValues.diploma}
                                error={profileFormTouches.diploma && profileFormErrors.diploma}
                            // helperText={profileFormTouches.diploma && profileFormErrors.diploma && t('expInfoPage.field-down-helper')}
                            // InputLabelProps={profileFormValues.diploma ? { shrink: true } : null}
                            />
                        }
                    </Grid>
                </Grid>
                <div className="separator"></div>
                <Grid item container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item md={5}>
                        <div className="information-form-title">
                            <span>
                                {t('expInfoPage.linkedin')} or {t('expInfoPage.resume')}
                                <sup>*</sup>
                            </span>
                        </div>
                    </Grid>
                    <Grid container item xs={12} md={7} gap={1}>
                        <Grid item xs={12}
                            container
                            direction="row"
                            alignItems="center"
                            gap={1}
                        >
                            {profileFormValues.resume
                                 ? <DownloadButton
                                    downloadParameters={{}}
                                    downloadFunction={getMyResume}
                                    label={profileFormValues.resume}
                                    fileName={profileFormValues.resume}
                                // fileName='resume.pdf'
                                />
                                : null
                            }
                            <UploadButton
                                downloadParameters={{}}
                                downloadFunction={postMyResume}
                                label={profileFormValues.resume ? t('button.overwrite') : t('button.upload')}
                                setFileName={setFileName}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            {loadingData
                                ? <Skeleton variant="rounded" height={'40px'} />
                                : <TextField
                                    fullWidth
                                    size="small"
                                    required
                                    type="url"
                                    pattern="https://.*"
                                    name="linkedin"
                                    placeholder="https://www.linkedin.com/in/"
                                    // label={t('expInfoPage.linkedin')}
                                    variant="outlined"
                                    onChange={handleInputChange}
                                    onBlur={handleBlur}
                                    value={profileFormValues.linkedin}
                                    // InputProps={{pattern:"https://.*"}}
                                    error={(profileFormTouches.linkedin && profileFormErrors.linkedin) || (profileFormValues.linkedin && !validURL(profileFormValues.linkedin))}
                                // helperText={profileFormTouches.linkedin && profileFormErrors.linkedin && t('expInfoPage.field-down-helper')}
                                // InputLabelProps={profileFormValues.linkedin ? { shrink: true } : null}
                                />
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <div className="separator"></div>
                <Grid item container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item md={5}>
                        <div className="information-form-title">
                            <span>
                                {t('expInfoPage.references')}
                            </span>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        {loadingData
                            ? <Skeleton variant="rounded" height={'132px'} />
                            : <TextField
                                fullWidth
                                size="small"
                                multiline
                                rows={5}
                                name="references"
                                // label={t('expInfoPage.references')}
                                variant="outlined"
                                onChange={handleInputChange}
                                // onBlur={handleBlur}
                                value={profileFormValues.references}
                            // error={profileFormTouches.references && profileFormErrors.references}
                            // helperText={profileFormTouches.references && profileFormErrors.references && t('expInfoPage.field-down-helper')}
                            // InputLabelProps={profileFormValues.references ? { shrink: true } : null}
                            />
                        }
                    </Grid>
                    <Grid item xs={12} textAlign={'right'} marginTop={"30px"}>
                        <Button
                            variant="contained"
                            disabled={!isEnabled()}
                            startIcon={<RiSave2Line />}
                            sx={{
                                backgroundColor: "var(--button-background-6)",
                                textTransform: 'none',
                                fontSize: '14px', fontWeight: '400', padding: '6px 10px',
                                ':hover': { backgroundColor: "var(--button-background-hover-6)" }
                            }}
                            onClick={(e) => { handleSubmit(e) }}
                        >
                            {t('button.save')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};
export default ExpInfoPage;