import '../../styles/Tables.css'

import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { Grid, Button, DialogContent, Dialog, DialogTitle, DialogActions } from '@mui/material';

import { RiMessage2Line, RiThumbUpLine, RiHistoryLine } from 'react-icons/ri';

import { ResponseSnackbarErrorHandler } from '../../components/ResponseSnackbar';
import { getRegistration, backToDraftRegistration, validateRegistration } from '../../services/registration.service';
import RegisterSummary from '../../components/RegisterSummary';
import RegisterHistory from '../../components/RegisterHistory';
import DialogComment from '../../components/DialogComment';
import DialogAlert from '../../components/DialogAlert';

import { UserInfosContext } from '../../context/UserInfosContext';


function RegistrationSingle({ userId }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient()

  const [dialOpenHistory, setDialOpenHistory] = useState(false);
  const [dialOpenComment, setDialOpenComment] = useState(false);
  const [dialOpenAccept, setDialOpenAccept] = useState(false);
  const { setSnackbarObj } = useContext(UserInfosContext);


  const handleClickOpenAccept = () => { setDialOpenAccept(true); };
  const handleClickOpenHistory = () => { setDialOpenHistory(true); };
  const handleClickOpenComment = () => { setDialOpenComment(true); };
  const handleClose = () => {
    setDialOpenAccept(false)
    setDialOpenHistory(false)
    setDialOpenComment(false)
  };

  const {
    data: registration,
    isLoading: loadingRegistration,
    error: errorRegistration,
    failureReason: failureRegistration,
    failureCount: failureCountRegistration,
    isFetching: fetchingRegistration
  } = useQuery({
    enabled: !!getAccessTokenSilently && !!userId, // ensures the query is only executed when these conditions are met.
    queryKey: ['registration', { _id: userId }],
    queryFn: () => getAccessTokenSilently().then((token) => getRegistration(token, userId)),
  });


  const validateRegistrationMutation = useMutation({
    enabled: !!getAccessTokenSilently && !!userId, // ensures the query is only executed when these conditions are met.
    mutationFn: () => getAccessTokenSilently().then((token) => validateRegistration(token, userId)),
    onSuccess: (data) => {
      queryClient.setQueryData(['registration', { _id: userId }], data) //update query data 
      queryClient.invalidateQueries({ queryKey: ['usersList'] }) //invalid query data, need refetch
      setSnackbarObj({
        message: t("snackbar.saved"),
        status: 'success',
        timestamp: new Date().getTime()
      })
      navigate('/admin/users/')
    },
    onError: (error) => {
      const snackbarError = ResponseSnackbarErrorHandler('registration', error)
      if (snackbarError) { setSnackbarObj(snackbarError) }
    }
  })

  const handleAccept = (e) => {
    e.preventDefault()
    validateRegistrationMutation.mutate()
  }

  const backToDraftRegistrationMutation = useMutation({
    enabled: !!getAccessTokenSilently && !!userId, // ensures the query is only executed when these conditions are met.
    mutationFn: (content) => getAccessTokenSilently().then((token) => backToDraftRegistration(token, userId, content)),
    onSuccess: (data) => {
      queryClient.setQueryData(['registration', { _id: userId }], data) //update query data 
      queryClient.invalidateQueries({ queryKey: ['usersList'] }) //invalid query data, need refetch
      setSnackbarObj({
        message: t("snackbar.saved"),
        status: 'success',
        timestamp: new Date().getTime()
      })
      navigate('/admin/users/')
    },
    onError: (error) => {
      const snackbarError = ResponseSnackbarErrorHandler('registration', error)
      if (snackbarError) { setSnackbarObj(snackbarError) }
    }
  })

  const handleBackToDraft = (e, content) => {
    e.preventDefault()
    backToDraftRegistrationMutation.mutate(content)
  }

  useEffect(() => {

    const snackbarRegistrationInfos = ResponseSnackbarErrorHandler('registration', errorRegistration, failureRegistration, failureCountRegistration)
    if (snackbarRegistrationInfos) { setSnackbarObj(snackbarRegistrationInfos) }

  }, [errorRegistration, failureCountRegistration, failureRegistration, setSnackbarObj]);

  return (
    <div>
      <div className='single-container widget'>
        <h5>{t('adminPages.userSingle.registration-title') + ' (' + (registration?.status || '') + ')'}</h5>
        {/* Grille principale */}
        <Grid container
          spacing={2}
          direction={{ xs: "column", md: 'row' }}
          justifyContent="space-around"
          alignItems="flex-start"
        >

          {/* La colonne de gauche (qui passe en dessous sur petit écran) */}
          <Grid item xs={12} md={9} id='alert-right-column'>
            <Grid container spacing={2} flexDirection={'column'} alignItems="stretch">
              <Grid item xs maxWidth={'100%'} width={'100%'}>
                {console.log(registration)}
                <RegisterSummary
                  personalInfos={registration?.personalInfo}
                  expInfos={registration?.expInfo}
                  companyInfos={registration?.companyInfo}
                  testInfos={registration?.testInfo}
                  loadingData={
                    (loadingRegistration && fetchingRegistration)
                  }
                  userId={userId}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* La colonne de droite (qui passe en dessous sur petit écran) */}
          <Grid item container spacing={2} direction="column" alignItems="normal" xs={12} md='auto'>
            <Grid item>
              <Button
                fullWidth
                variant="contained"
                startIcon={<RiMessage2Line />}
                disabled={registration?.status !== 'Pending'}
                sx={{
                  backgroundColor: "var(--button-background-4)",
                  textTransform: 'none',
                  fontSize: '14px', fontWeight: '400', padding: '6px 10px', marginRight: '10px',
                  ':hover': { backgroundColor: "var(--button-background-hover-4)" }
                }}
                onClick={(e) => { handleClickOpenComment(e) }}
              >
                {t('button.backDraft')}
              </Button>
            </Grid>

            <Grid item>
              <Button
                fullWidth
                variant="contained"
                startIcon={<RiHistoryLine />}
                sx={{
                  backgroundColor: "var(--button-background-5)",
                  textTransform: 'none',
                  fontSize: '14px', fontWeight: '400', padding: '6px 10px', marginRight: '10px',
                  ':hover': { backgroundColor: "var(--button-background-hover-4)" }
                }}
                onClick={(e) => { handleClickOpenHistory(e) }}
              >
                {t('button.history')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                fullWidth
                variant="contained"
                startIcon={<RiThumbUpLine />}
                disabled={registration?.status !== 'Pending'}
                sx={{
                  backgroundColor: "var(--button-background-6)",
                  textTransform: 'none',
                  fontSize: '14px', fontWeight: '400', padding: '6px 10px', marginRight: '10px',
                  ':hover': { backgroundColor: "var(--button-background-hover-6)" }
                }}
                onClick={(e) => { handleClickOpenAccept(e) }}
              >
                {t('button.accept')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Dialog
        fullWidth
        maxWidth='md'
        open={dialOpenHistory}
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('header.label-History')}
        </DialogTitle>
        <DialogContent sx={{ padding: { xs: 0, sm: '16px' } }}>
          <RegisterHistory history={registration?.history} loadingData={(loadingRegistration && fetchingRegistration)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()}>{t('button.close')}</Button>
        </DialogActions>
      </Dialog>
      <div>
        <DialogAlert
          maxWidth='sm'
          dialOpen={dialOpenAccept}
          title={t('adminPages.userSingle.dialog-accept-title')}
          content1={t('adminPages.userSingle.dialog-accept-content') + '?'}
          content2={t('adminPages.userSingle.dialog-accept-content2')}
          handleClose={() => handleClose()}
          handleConfirm={(e) => handleAccept(e)}
        />
        <DialogComment
          maxWidth='md'
          commentRequired
          dialOpen={dialOpenComment}
          title={t('adminPages.userSingle.dialog-comment-title')}
          handleClose={() => handleClose()}
          handleConfirm={(e, content) => handleBackToDraft(e, content)}
        />
      </div>
    </div >
  );
}

export default RegistrationSingle;