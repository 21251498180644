import React from 'react';

import { useTranslation } from "react-i18next";
import { useAuth0 } from '@auth0/auth0-react';

import { Button } from '@mui/material';

import '../styles/LoginPage.css'
import loginPageImage from '../assets/images/loginPageImage.webp'

import LoginBanner from './LoginBanner'

function ConfirmEmail() {

    const { t } = useTranslation();
    const { loginWithRedirect } = useAuth0();

    return (
        <div className='login-page' style={{ backgroundImage: `url(${loginPageImage})` }}>
            <LoginBanner />
            <div className='login-content'>
                <div className='login-form'>
                    <div className='register-form' style={{display: 'flex', gap: '30px', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '20px'}}>
                        <div className='login-form-titles' style={{ textAlign: 'center' }}>{t('confirmEmail.title')}</div>
                        <p>{t('confirmEmail.subtitle')}</p>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: "var(--button-background-8)",
                                    textTransform: 'none',
                                    fontSize: '14px', fontWeight: '400', padding: '6px 10px',
                                    width: '80px',
                                    ':hover': { backgroundColor: "var(--button-background-hover-6)" }
                                }}
                                onClick={() => { loginWithRedirect() }}
                            >
                                {t('confirmEmail.button')}
                            </Button>

                    </div>
                </div>
            </div>
        </div>

    )
}

export default ConfirmEmail