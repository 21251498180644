
import '../../styles/InfoPages.css'

import React from "react";
import { useTranslation } from "react-i18next";

import LicenseItem from '../../components/LicenseItem'
import LicenseHunterImg from '../../assets/images/license-hunter-image.webp'
import LicenseHunterImgSmall from '../../assets/images/license-hunter-image-small.webp'
import LicenseAnalystImg from '../../assets/images/license-analyst-image.webp'
import LicenseAnalystImgSmall from '../../assets/images/license-analyst-image-small.webp'

import { useProgressiveImage } from '../../hooks/useProgressiveImage';

function TestPage({ testInfos, loadingData }) {

    const { t } = useTranslation();
    const dojoURI = process.env.REACT_APP_DOJO_URI

    const LicenseAnalystImgLoaded = useProgressiveImage(LicenseAnalystImg) || LicenseAnalystImgSmall
    const LicenseHunterImgLoaded = useProgressiveImage(LicenseHunterImg) || LicenseHunterImgSmall

    return (
        <div className='information-form'>
            <h3 style={{alignSelf: 'flex-start'}}>{t('registerMenu.test')}</h3>
            <p>{t('testInfoPage.intro')}</p>
            <LicenseItem
                name={t('testInfoPage.title-analyst')}
                image={LicenseAnalystImgLoaded}
                status={testInfos?.analystTest?.status}
                availableDate={testInfos?.analystTest?.availableDate}
                rejectReason={testInfos?.analystTest?.reject_reason}
                uri={dojoURI + '/alerts'}
                presentation={t('testInfoPage.presentation-analyst')}
                description={t('testInfoPage.description-analyst')}
                video={'https://www.youtube.com/watch?v=WsN8rN9spFk'}
                loadingData = {loadingData}
            />
            <LicenseItem
                name={t('testInfoPage.title-hunter')}
                image={LicenseHunterImgLoaded}
                status={testInfos?.hunterTest?.status}
                availableDate={testInfos?.hunterTest?.availableDate}
                rejectReason={testInfos?.hunterTest?.reject_reason}
                uri={dojoURI + '/campaigns'}
                presentation={t('testInfoPage.presentation-hunter')}
                description={t('testInfoPage.description-hunter')}
                video={'https://www.youtube.com/watch?v=kOvR0KDuls4'}
                loadingData = {loadingData}
            />
        </div>
    );
};
export default TestPage;