// TODO revoir l'historique : les subjects
import nybbleLogo from '../assets/nybble-logo/Nybble-Logo-White.svg'
import '../styles/RegisterHistory.css'

import React from 'react';
import { useTranslation } from "react-i18next";

import { Grid } from '@mui/material';

import { GetRelativeTime, getInitials } from '../utils/formatDate'
import { BreakLineRender } from '../utils/formatText'
import SkeletonHistory from '../components/SkeletonHistory';


function RegisterHistory({history, loadingData}) {
    const { t } = useTranslation();

    if (loadingData) return <SkeletonHistory rows={4} />

    if (!history || history.length === 0) {
        // render nothing
        return <p>{t('adminPages.userSingle.history-info-noData')}</p>
    }
    else {
        return (
            <div>
                <div className="registration__history-container">
                    {history.slice(0).reverse().map((element, i) => (
                        <div key={i} className='registration__history-box widget'>
                            <Grid item container spacing={2} xs={12}
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                wrap='no'
                                width={'100%'}
                            >
                                <Grid item container xs={'auto'}>
                                    {(element.profile === 'Admin' || element.profile === 'Robot')
                                        ? (
                                            <div className='avatar'>
                                                <img src={nybbleLogo} alt='nybbleLogo' />
                                            </div>
                                        ) : (
                                            <div className='avatar'>
                                                {getInitials(element.author)}
                                            </div>
                                        )}
                                </Grid>
                                <Grid item container spacing={0.5} justifyContent={'flex-start'} flexDirection={'column'} >
                                        <span className='registration__history-user'>
                                            {element.author}
                                        </span>
                                
                                        <span className='registration__history-subject'>
                                            {t(`history.subject.${element.subject}`)}      
                                    </span>
                                </Grid>
                                <Grid item container xs={2.5} justifyContent={'flex-end'} >
                                    <span className='registration__history-date'>
                                        {GetRelativeTime(element.timestamp, t('lang'))}
                                    </span>
                                </Grid>
                            </Grid>
                            {element.content ? (
                                    <Grid item container xs={12} justifyContent={'flex-start'} marginTop={'30px'}>
                                    <span className='registration__history-description'>
                                        <BreakLineRender content={element.content} />
                                    </span>
                                </Grid>
                                ):null}
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}
export default RegisterHistory