import '../styles/LoginPage.css'

import React, { useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from "react-i18next";

import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function LoginForm() {

    const { t } = useTranslation();
    const { loginWithRedirect } = useAuth0();
    const [toggleButton, setToggleButton] = useState(false);

    let navigate = useNavigate();

    const handleClickJoinUs = () => navigate('/register');

    return (
        <div>
            <div className='login-form-subContainer login-form-subContainer1'>
                <span className='login-form-titles login-form-titles1'>Community based cyberdefense</span>
                <div className='login-form-toggleButton-container'>
                    <Button
                        className={toggleButton ? 'slide_from_right' : 'slide_from_right_active'}
                        disableRipple
                        sx={{
                            textTransform: 'none',
                            fontSize: '16px', fontWeight: '400',
                            padding: '0px', width: '100%',
                            color: 'white',
                            borderRadius: "0px",
                        }}
                        onClick={() => { setToggleButton(!toggleButton) }}
                    >
                        {t('login.client-tab')}
                    </Button>
                    <Button
                        className={!toggleButton ? 'slide_from_left' : 'slide_from_left_active'}
                        disableRipple
                        sx={{
                            textTransform: 'none',
                            fontSize: '16px', fontWeight: '400',
                            padding: '0px', width: '100%',
                            color: 'white',
                            borderRadius: "0px",
                        }}
                        onClick={() => { setToggleButton(!toggleButton) }}
                    >
                        {t('login.hunter-tab')}
                    </Button>
                </div>
                <div className={toggleButton ? 'login-form-subContainer1 login-form-text-container' : 'login-form-subContainer1 login-form-text-container login-form-text-container_active'}>
                    <div id='login-form-text-slide1' className='login-form-subtitles'>
                        <div>
                            <span>{t('login.client-paragraph')}</span>
                            <br />
                            <span>{t('login.client-paragraph2')}</span>
                        </div>
                        <div className='login-form-subtitles-buttons'>
                            <Button
                                onClick={() => loginWithRedirect()}
                                sx={{
                                    color: 'white',
                                    backgroundColor: "var(--button-background-8)",
                                    textTransform: 'none',
                                    fontSize: { xs: '16px', sm: '20px' }, fontWeight: '400',
                                    padding: '6px 10px', marginBottom: '10px',
                                    borderRadius: '15px',
                                    ':hover': { backgroundColor: "var(--button-background-hover-8)" }
                                }}
                            >
                                {t('login.button-connect')}
                            </Button>
                            <div>
                                <span>{t('login.notRegistered')}</span>
                                <Button
                                    href={t('links.contact-link')}
                                    title={t('links.contact-link')} aria-label={t('links.contact-link')}
                                    target="_blank" rel="noopener noreferrer"
                                    variant="contained"
                                    sx={{
                                        color: 'white',
                                        textTransform: 'none',
                                        fontSize: '13px', fontWeight: '400',
                                        padding: '3px 10px', marginLeft: '10px',
                                        borderRadius: '10px',
                                        border: ' 1px solid var(--nhub-color-background-6)',
                                        backgroundColor: "var(--button-background-hover-9)",
                                        ':hover': { backgroundColor: "var(--button-background-9)" }
                                    }}
                                >
                                    {t('login.button-contact')}
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div id='login-form-text-slide2' className='login-form-subtitles'>
                        <div>
                            <span>{t('login.hunter-paragraph')}</span>
                            <br />
                            <span>{t('login.hunter-paragraph2')}</span>
                        </div>
                        <div className='login-form-subtitles-buttons'>
                            <Button
                                onClick={() => loginWithRedirect()}
                                sx={{
                                    color: 'white',
                                    backgroundColor: "var(--button-background-8)",
                                    textTransform: 'none',
                                    fontSize: { xs: '16px', sm: '20px' }, fontWeight: '400',
                                    padding: '6px 10px', marginBottom: '10px',
                                    borderRadius: '15px',
                                    ':hover': { backgroundColor: "var(--button-background-hover-8)" }
                                }}
                            >
                                {t('login.button-connect')}
                            </Button>
                            <div>
                                <span>{t('login.notRegistered')}</span>
                                <Button
                                    //href={t('links.join-link')}
                                    title={t('links.join-link')} aria-label={t('links.join-link')}
                                    target="_blank" rel="noopener noreferrer"
                                    variant="contained"
                                    sx={{
                                        color: 'white',
                                        textTransform: 'none',
                                        fontSize: '13px', fontWeight: '400',
                                        padding: '3px 10px', marginLeft: '10px',
                                        borderRadius: '10px',
                                        border: ' 1px solid var(--nhub-color-background-6)',
                                        backgroundColor: "var(--button-background-hover-9)",
                                        ':hover': { backgroundColor: "var(--button-background-9)" }
                                    }}
                                    onClick={() => { handleClickJoinUs() }} // lorsqu'on ouvrira
                                >
                                    {t('login.button-join')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default LoginForm;